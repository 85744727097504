import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  }
}));

function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h1" className={classes.title}>
          Privacy Policy
        </Typography>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We collect information that you provide directly to us, including your name, email address, and any feedback or content you submit through our rant analyzer platform.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We use the information we collect to:
          </Typography>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Analyze and improve user experience</li>
            <li>Generate reports and analytics</li>
            <li>Communicate with you about our services</li>
          </ul>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            3. Data Security
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We implement appropriate security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            4. Data Retention
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We retain your information for as long as necessary to provide our services and comply with legal obligations.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            5. Your Rights
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            You have the right to access, correct, or delete your personal information. Contact us to exercise these rights.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            6. Updates to This Policy
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="body1" className={classes.paragraph}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default PrivacyPolicy; 