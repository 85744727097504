import React from 'react';
import { Paper, Container, Link, makeStyles } from '@material-ui/core';
import Clock from './Clock';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 0),
    zIndex: theme.zIndex.appBar - 1,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  links: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  poweredBy: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  poweredByLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}));

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Paper component="footer" elevation={3} className={classes.footer}>
      <Container maxWidth="lg" className={classes.container}>
        <div>© {currentYear} Rant Analyzer</div>
        <div className={classes.links}>
          <Link href="/privacy" className={classes.link}>
            Privacy Policy
          </Link>
          <Link href="/terms" className={classes.link}>
            Terms of Service
          </Link>
        </div>
        <div className={classes.poweredBy}>
          Powered by{' '}
          <Link 
            href="https://shipyond.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className={classes.poweredByLink}
          >
            ShipYOND
          </Link>
        </div>
        <Clock />
      </Container>
    </Paper>
  );
}

export default Footer; 