// Import modern style logos
import modernLogo16 from '../icons/modern/logo-16x16.png';
import modernLogo32 from '../icons/modern/logo-32x32.png';
import modernLogo48 from '../icons/modern/logo-48x48.png';
import modernLogo64 from '../icons/modern/logo-64x64.png';
import modernLogo96 from '../icons/modern/logo-96x96.png';
import modernLogo128 from '../icons/modern/logo-128x128.png';
import modernLogo192 from '../icons/modern/logo-192x192.png';
import modernLogo512 from '../icons/modern/logo-512x512.png';

// Import minimal style logos
import minimalLogo16 from '../icons/minimal/logo-16x16.png';
import minimalLogo32 from '../icons/minimal/logo-32x32.png';
import minimalLogo48 from '../icons/minimal/logo-48x48.png';
import minimalLogo64 from '../icons/minimal/logo-64x64.png';
import minimalLogo96 from '../icons/minimal/logo-96x96.png';
import minimalLogo128 from '../icons/minimal/logo-128x128.png';
import minimalLogo192 from '../icons/minimal/logo-192x192.png';
import minimalLogo512 from '../icons/minimal/logo-512x512.png';

// Import circular style logos
import circularLogo16 from '../icons/circular/logo-16x16.png';
import circularLogo32 from '../icons/circular/logo-32x32.png';
import circularLogo48 from '../icons/circular/logo-48x48.png';
import circularLogo64 from '../icons/circular/logo-64x64.png';
import circularLogo96 from '../icons/circular/logo-96x96.png';
import circularLogo128 from '../icons/circular/logo-128x128.png';
import circularLogo192 from '../icons/circular/logo-192x192.png';
import circularLogo512 from '../icons/circular/logo-512x512.png';

export const logos = {
  modern: {
    favicon16: modernLogo16,
    favicon32: modernLogo32,
    favicon48: modernLogo48,
    logo64: modernLogo64,
    logo96: modernLogo96,
    logo128: modernLogo128,
    logo192: modernLogo192,
    logo512: modernLogo512,
  },
  minimal: {
    favicon16: minimalLogo16,
    favicon32: minimalLogo32,
    favicon48: minimalLogo48,
    logo64: minimalLogo64,
    logo96: minimalLogo96,
    logo128: minimalLogo128,
    logo192: minimalLogo192,
    logo512: minimalLogo512,
  },
  circular: {
    favicon16: circularLogo16,
    favicon32: circularLogo32,
    favicon48: circularLogo48,
    logo64: circularLogo64,
    logo96: circularLogo96,
    logo128: circularLogo128,
    logo192: circularLogo192,
    logo512: circularLogo512,
  }
};

export const getLogoBySize = (style = 'modern', size = 32) => {
  const styleLogos = logos[style];
  switch (size) {
    case 16:
      return styleLogos.favicon16;
    case 32:
      return styleLogos.favicon32;
    case 48:
      return styleLogos.favicon48;
    case 64:
      return styleLogos.logo64;
    case 96:
      return styleLogos.logo96;
    case 128:
      return styleLogos.logo128;
    case 192:
      return styleLogos.logo192;
    case 512:
      return styleLogos.logo512;
    default:
      return styleLogos.logo64;
  }
};

export default logos; 