import React, { createContext, useState, useContext, useMemo } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { indigo, teal, pink, amber, grey, red, green } from '@material-ui/core/colors';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('darkMode') === 'true'
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: isDarkMode ? 'dark' : 'light',
          primary: {
            light: isDarkMode ? indigo[300] : indigo[300],
            main: isDarkMode ? indigo[200] : indigo[500],
            dark: isDarkMode ? indigo[100] : indigo[700],
            contrastText: isDarkMode ? '#000000' : '#ffffff',
          },
          secondary: {
            light: isDarkMode ? teal[300] : teal[300],
            main: isDarkMode ? teal[200] : teal[500],
            dark: isDarkMode ? teal[100] : teal[700],
            contrastText: isDarkMode ? '#000000' : '#ffffff',
          },
          error: {
            light: isDarkMode ? red[300] : red[300],
            main: isDarkMode ? red[500] : red[700],
            dark: isDarkMode ? red[700] : red[900],
          },
          success: {
            light: isDarkMode ? green[300] : green[300],
            main: isDarkMode ? green[500] : green[700],
            dark: isDarkMode ? green[700] : green[900],
          },
          warning: {
            light: amber[300],
            main: amber[500],
            dark: amber[700],
          },
          background: {
            default: isDarkMode ? '#0a1929' : '#f7f9fc',
            paper: isDarkMode ? '#1a2027' : '#ffffff',
            alternate: isDarkMode ? '#132f4c' : grey[50],
          },
          text: {
            primary: isDarkMode ? '#ffffff' : grey[900],
            secondary: isDarkMode ? grey[400] : grey[700],
          },
          action: {
            active: isDarkMode ? teal[200] : teal[500],
            hover: isDarkMode ? 'rgba(3, 169, 244, 0.08)' : 'rgba(3, 169, 244, 0.04)',
            selected: isDarkMode ? 'rgba(3, 169, 244, 0.16)' : 'rgba(3, 169, 244, 0.08)',
          },
          divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
        },
        typography: {
          fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          h1: { fontWeight: 600 },
          h2: { fontWeight: 600 },
          h3: { fontWeight: 600 },
          h4: { fontWeight: 600 },
          h5: { fontWeight: 600 },
          h6: { fontWeight: 600 },
        },
        shape: {
          borderRadius: 12,
        },
        overrides: {
          MuiAppBar: {
            root: {
              backgroundColor: isDarkMode ? '#1a2027' : indigo[500],
              backgroundImage: isDarkMode 
                ? 'linear-gradient(to right, #1a2027, #132f4c)'
                : 'linear-gradient(to right, #3949ab, #1e88e5)',
              color: '#ffffff',
              boxShadow: 'none',
              borderBottom: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
            },
          },
          MuiCard: {
            root: {
              backgroundColor: isDarkMode ? '#1a2027' : '#ffffff',
              boxShadow: isDarkMode 
                ? '0 4px 6px rgba(0, 0, 0, 0.3)' 
                : '0 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: isDarkMode 
                  ? '0 6px 12px rgba(0, 0, 0, 0.4)' 
                  : '0 6px 12px rgba(0, 0, 0, 0.15)',
              },
            },
          },
          MuiButton: {
            root: {
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 8,
            },
            contained: {
              boxShadow: 'none',
              '&:hover': {
                boxShadow: isDarkMode 
                  ? '0 2px 4px rgba(0,0,0,0.4)' 
                  : '0 2px 4px rgba(0,0,0,0.2)',
              },
            },
            containedPrimary: {
              background: isDarkMode 
                ? 'linear-gradient(45deg, #3949ab 30%, #1e88e5 90%)'
                : 'linear-gradient(45deg, #3949ab 30%, #1e88e5 90%)',
              '&:hover': {
                background: isDarkMode 
                  ? 'linear-gradient(45deg, #283593 30%, #1976d2 90%)'
                  : 'linear-gradient(45deg, #283593 30%, #1976d2 90%)',
              },
            },
          },
          MuiChip: {
            root: {
              borderRadius: 8,
            },
          },
          MuiPaper: {
            rounded: {
              borderRadius: 12,
            },
            elevation1: {
              boxShadow: isDarkMode 
                ? '0 2px 4px rgba(0,0,0,0.4)' 
                : '0 2px 4px rgba(0,0,0,0.1)',
            },
            elevation2: {
              boxShadow: isDarkMode 
                ? '0 4px 8px rgba(0,0,0,0.4)' 
                : '0 4px 8px rgba(0,0,0,0.1)',
            },
          },
        },
        status: {
          pending: {
            color: amber[700],
            backgroundColor: isDarkMode ? amber[900] + '20' : amber[50],
          },
          responded: {
            color: green[500],
            backgroundColor: isDarkMode ? green[900] + '20' : green[50],
          },
          urgent: {
            color: pink[500],
            backgroundColor: isDarkMode ? pink[900] + '20' : pink[50],
          },
        },
      }),
    [isDarkMode]
  );

  const toggleTheme = () => {
    setIsDarkMode((prev) => {
      const newValue = !prev;
      localStorage.setItem('darkMode', newValue);
      return newValue;
    });
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
