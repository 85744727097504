import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  statusPending: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  statusResponded: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  statusUrgent: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  }
}));

function RantStatus({ status }) {
  const classes = useStyles();

  const getStatusProps = () => {
    switch (status) {
      case 'Pending':
        return {
          className: classes.statusPending,
          label: 'Pending'
        };
      case 'Responded':
        return {
          className: classes.statusResponded,
          label: 'Responded'
        };
      case 'Urgent':
        return {
          className: classes.statusUrgent,
          label: 'Urgent'
        };
      default:
        return {
          className: '',
          label: status
        };
    }
  };

  const { className, label } = getStatusProps();

  return (
    <Chip
      className={`${classes.root} ${className}`}
      label={label}
      size="small"
    />
  );
}

export default RantStatus; 