import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Container,
  Paper,
  Typography,
  Divider,
  Chip,
  Button,
  TextField,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  makeStyles
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import {
  Visibility
} from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  metadata: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  ratingSection: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  commentsSection: {
    marginTop: theme.spacing(3),
  },
  commentForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

function KnowledgeBaseArticleDetail() {
  const classes = useStyles();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [article, setArticle] = useState(null);
  const [userRating, setUserRating] = useState(0);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchArticle = useCallback(async () => {
    try {
      const response = await api.get(`/api/knowledge-base/articles/${id}`);
      setArticle(response.data);
      const userRating = response.data.ratings.find(r => r.user === user.id);
      if (userRating) {
        setUserRating(userRating.score);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching article:', error);
      setLoading(false);
    }
  }, [id, user]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  const handleRating = async (value) => {
    try {
      await api.post(`/api/knowledge-base/articles/${id}/rate`, {
        score: value
      });
      fetchArticle();
    } catch (error) {
      console.error('Error rating article:', error);
    }
  };

  const handleComment = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/api/knowledge-base/articles/${id}/comments`, {
        content: comment
      });
      setComment('');
      fetchArticle();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!article) {
    return <Typography>Article not found</Typography>;
  }

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <Box p={3}>
          <Typography variant="h4" className={classes.header}>
            {article.title}
          </Typography>

          <div className={classes.metadata}>
            <Chip
              icon={<Visibility />}
              label={`${article.views} views`}
              size="small"
              className={classes.chip}
            />
            <Chip
              label={article.category.name}
              size="small"
              className={classes.chip}
            />
            {article.tags.map(tag => (
              <Chip
                key={tag}
                label={tag}
                size="small"
                className={classes.chip}
              />
            ))}
          </div>

          <Typography variant="body1" className={classes.content}>
            {article.content}
          </Typography>

          <Divider />

          <Box className={classes.ratingSection}>
            <Typography variant="h6" gutterBottom>
              Rate this article
            </Typography>
            <Rating
              value={userRating}
              onChange={(event, newValue) => handleRating(newValue)}
            />
            <Typography variant="body2" color="textSecondary">
              Average rating: {article.averageRating.toFixed(1)} ({article.ratings.length} ratings)
            </Typography>
          </Box>

          <div className={classes.commentsSection}>
            <Typography variant="h6" gutterBottom>
              Comments ({article.comments.length})
            </Typography>

            <form onSubmit={handleComment} className={classes.commentForm}>
              <TextField
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="Add a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!comment.trim()}
                style={{ marginTop: 8 }}
              >
                Add Comment
              </Button>
            </form>

            <List>
              {article.comments.map((comment, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar>{comment.user.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={comment.user.name}
                    secondary={
                      <>
                        <Typography component="span" variant="body2">
                          {comment.content}
                        </Typography>
                        <br />
                        <Typography component="span" variant="caption" color="textSecondary">
                          {new Date(comment.createdAt).toLocaleString()}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Box>
      </Paper>
    </Container>
  );
}

export default KnowledgeBaseArticleDetail; 