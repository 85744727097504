import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  list: {
    width: '100%',
  },
  categoryCount: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  }
}));

function KnowledgeBaseCategories({ onCategoryChange }) {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/knowledge-base/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCategory) {
        await api.put(`/api/knowledge-base/categories/${selectedCategory._id}`, formData);
      } else {
        await api.post('/api/knowledge-base/categories', formData);
      }
      fetchCategories();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleDelete = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await api.delete(`/api/knowledge-base/categories/${categoryId}`);
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  const handleOpenDialog = (category = null) => {
    setSelectedCategory(category);
    setFormData(category || { name: '', description: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedCategory(null);
    setFormData({ name: '', description: '' });
    setOpenDialog(false);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Categories</Typography>
        <Button
          startIcon={<AddIcon />}
          color="primary"
          onClick={() => handleOpenDialog()}
        >
          Add Category
        </Button>
      </div>

      <List className={classes.list}>
        {categories.map((category) => (
          <ListItem key={category._id} button onClick={() => onCategoryChange(category._id)}>
            <ListItemText 
              primary={category.name}
              secondary={category.description}
            />
            <Typography variant="caption" className={classes.categoryCount}>
              ({category.articleCount || 0} articles)
            </Typography>
            <ListItemSecondaryAction>
              <Tooltip title="Edit Category">
                <IconButton edge="end" onClick={() => handleOpenDialog(category)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Category">
                <IconButton edge="end" onClick={() => handleDelete(category._id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedCategory ? 'Edit Category' : 'Add Category'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {selectedCategory ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default KnowledgeBaseCategories; 