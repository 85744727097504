import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  LinearProgress,
  Button,
  ButtonGroup,
  TableSortLabel,
} from '@material-ui/core';
import { Line, Pie } from 'react-chartjs-2';
import api from '../utils/api';
import RantStatus from './RantStatus';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { 
  GridOn as ExcelIcon, 
  ViewColumn as CsvIcon,
  Description as HtmlIcon
} from '@material-ui/icons';
import { io } from 'socket.io-client';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
    '& > .MuiGrid-container': {
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12)
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  chartContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  metricsCard: {
    backgroundColor: theme.palette.background.paper,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
  },
  statValue: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  trendPositive: {
    color: theme.palette.success.main,
  },
  trendNegative: {
    color: theme.palette.error.main,
  },
  departmentSelect: {
    marginBottom: theme.spacing(3),
    minWidth: 200,
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  formControls: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    minWidth: 200,
  },
  selectedDepartmentChip: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    },
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200
  },
  contentCell: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableContainer: {
    overflowX: 'auto',
    marginTop: theme.spacing(2)
  }
}));

function DepartmentMetrics() {
  const classes = useStyles();
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date()
  });
  const [metrics, setMetrics] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');

  const fetchMetrics = useCallback(async () => {
    try {
      const response = await api.get('/api/department-metrics', {
        params: {
          department: selectedDepartment,
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString()
        }
      });
      setMetrics(response.data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  }, [selectedDepartment, dateRange]);

  // Fetch departments for dropdown
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await api.get('/api/manage/target-departments');
        setDepartments(response.data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  useEffect(() => {
    const socket = io('http://localhost:5008');

    socket.on('newRant', () => {
      fetchMetrics(); // Refresh data when new rant is added
    });

    socket.on('rantUpdated', () => {
      fetchMetrics(); // Refresh data when rant is updated
    });

    return () => {
      socket.disconnect();
    };
  }, [fetchMetrics]);

  const handleDateRangeChange = (type) => (date) => {
    setDateRange(prev => ({
      ...prev,
      [type]: date
    }));
  };

  const handleExportExcel = async () => {
    try {
      const response = await api.get('/api/reports/export/excel', {
        params: {
          department: selectedDepartment,
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString()
        },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `department-metrics-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting Excel:', error);
      // Add error notification here
    }
  };

  const handleExportCSV = async () => {
    try {
      const response = await api.get('/api/reports/export/csv', {
        params: {
          department: selectedDepartment,
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString()
        },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `department-metrics-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting CSV:', error);
      // Add error notification here
    }
  };

  const handleExportHTML = async () => {
    try {
      // Get all chart canvases
      const trendsCanvas = document.querySelector('#trendsChart canvas');
      const categoryCanvas = document.querySelector('#categoryChart canvas');
      const departmentCanvas = document.querySelector('#departmentChart canvas');

      if (!trendsCanvas || !categoryCanvas || !departmentCanvas) {
        console.error('One or more chart canvases not found');
        return;
      }

      // Get department name from the departments list
      const departmentName = selectedDepartment === 'all' 
        ? 'All Departments' 
        : departments.find(d => d._id === selectedDepartment)?.name || 'Unknown Department';

      // Convert charts to base64 images
      const trendsChartImage = trendsCanvas.toDataURL('image/png');
      const categoryChartImage = categoryCanvas.toDataURL('image/png');
      const departmentChartImage = departmentCanvas.toDataURL('image/png');

      // Get HTML content
      const response = await api.post('/api/reports/export/html', {
        department: selectedDepartment,
        departmentName: departmentName,
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
        trendsChart: trendsChartImage,
        categoryChart: categoryChartImage,
        departmentChart: departmentChartImage,
        metrics: metrics
      }, {
        responseType: 'text'
      });

      // Open in new window
      const newWindow = window.open();
      newWindow.document.write(response.data);
      newWindow.document.close();
    } catch (error) {
      console.error('Error exporting HTML:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedRants = (rants) => {
    return rants.sort((a, b) => {
      if (orderBy === 'date') {
        return order === 'asc' 
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }
      
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (!aValue) return order === 'asc' ? -1 : 1;
      if (!bValue) return order === 'asc' ? 1 : -1;

      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  };

  if (!metrics) return <LinearProgress />;

  return (
    <Container maxWidth="lg" className={classes.root}>
      {/* Filters Section */}
      <Paper className={classes.paper} style={{ marginBottom: '24px' }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Department</InputLabel>
              <Select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                label="Department"
              >
                <MenuItem value="all">All Departments</MenuItem>
                {departments && departments.map(dept => (
                  <MenuItem key={dept._id} value={dept._id}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                variant="inline"
                format="MM/dd/yyyy"
                label="Start Date"
                value={dateRange.startDate}
                onChange={handleDateRangeChange('startDate')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                variant="inline"
                format="MM/dd/yyyy"
                label="End Date"
                value={dateRange.endDate}
                onChange={handleDateRangeChange('endDate')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup variant="outlined" color="primary">
              <Button startIcon={<ExcelIcon />} onClick={handleExportExcel}>
                Export Excel
              </Button>
              <Button startIcon={<CsvIcon />} onClick={handleExportCSV}>
                Export CSV
              </Button>
              <Button startIcon={<HtmlIcon />} onClick={handleExportHTML}>
                View Report
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>

      {/* Summary Cards */}
      <Grid container spacing={3} style={{ marginBottom: '24px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.metricsCard}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Total Rants
              </Typography>
              <Typography variant="h4" className={classes.statValue}>
                {metrics.totalRants}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.metricsCard}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Resolved Rants
              </Typography>
              <Typography variant="h4" className={classes.statValue}>
                {metrics.resolvedRants}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.metricsCard}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Resolution Rate
              </Typography>
              <Typography variant="h4" className={classes.statValue}>
                {metrics.resolutionRate}%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.metricsCard}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Average Response Time
              </Typography>
              <Typography variant="h4" className={classes.statValue}>
                {metrics.avgResponseTime} days
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts Section - Fixed Layout */}
      <Grid container spacing={3} style={{ marginBottom: '24px' }}>
        {/* Daily Trends Chart */}
        <Grid item xs={12} lg={6}>
          <Paper className={classes.chartContainer}>
            <Typography variant="h6" gutterBottom>
              Daily Trends
            </Typography>
            <div id="trendsChart" style={{ height: '400px', position: 'relative' }}>
              {metrics?.trendData ? (
                <Line
                  data={{
                    labels: metrics.trendData.map(d => new Date(d._id).toLocaleDateString()),
                    datasets: [
                      {
                        label: 'Total Rants',
                        data: metrics.trendData.map(d => d.totalRants),
                        borderColor: '#1976d2',
                        fill: false,
                        tension: 0.4
                      },
                      {
                        label: 'Resolved',
                        data: metrics.trendData.map(d => d.resolvedRants),
                        borderColor: '#4caf50',
                        fill: false,
                        tension: 0.4
                      }
                    ]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          stepSize: 1
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        position: 'top'
                      }
                    }
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </div>
          </Paper>
        </Grid>

        {/* Category Distribution Chart */}
        <Grid item xs={12} lg={3}>
          <Paper className={classes.chartContainer}>
            <Typography variant="h6" gutterBottom>
              Category Distribution
            </Typography>
            <div id="categoryChart" style={{ height: '400px', position: 'relative' }}>
              {metrics?.categoryDistribution ? (
                <Pie
                  data={{
                    labels: metrics.categoryDistribution.map(c => c.category),
                    datasets: [{
                      data: metrics.categoryDistribution.map(c => c.count),
                      backgroundColor: [
                        '#1976d2',
                        '#4caf50',
                        '#ff9800',
                        '#f44336',
                        '#9c27b0',
                        '#2196f3',
                        '#00bcd4',
                        '#009688'
                      ]
                    }]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'right',
                        align: 'center'
                      }
                    }
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </div>
          </Paper>
        </Grid>

        {/* Department Distribution Chart - New Addition */}
        <Grid item xs={12} lg={3}>
          <Paper className={classes.chartContainer}>
            <Typography variant="h6" gutterBottom>
              Department Distribution
            </Typography>
            <div id="departmentChart" style={{ height: '400px', position: 'relative' }}>
              {metrics?.recentRants ? (
                <Pie
                  data={{
                    labels: [...new Set(metrics.recentRants.map(r => r.department))],
                    datasets: [{
                      data: [...new Set(metrics.recentRants.map(r => r.department))].map(dept =>
                        metrics.recentRants.filter(r => r.department === dept).length
                      ),
                      backgroundColor: [
                        '#673ab7',
                        '#e91e63',
                        '#2196f3',
                        '#4caf50',
                        '#ff9800',
                        '#795548',
                        '#607d8b',
                        '#9c27b0'
                      ]
                    }]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'right',
                        align: 'center'
                      }
                    }
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>

      {/* Recent Rants Table */}
      <Paper className={classes.paper} style={{ marginTop: '24px' }}>
        <Typography variant="h6" gutterBottom>
          Recent Rants
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={() => handleRequestSort('date')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'author'}
                    direction={orderBy === 'author' ? order : 'asc'}
                    onClick={() => handleRequestSort('author')}
                  >
                    Author
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'target'}
                    direction={orderBy === 'target' ? order : 'asc'}
                    onClick={() => handleRequestSort('target')}
                  >
                    Target
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'department'}
                    direction={orderBy === 'department' ? order : 'asc'}
                    onClick={() => handleRequestSort('department')}
                  >
                    Department
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'category'}
                    direction={orderBy === 'category' ? order : 'asc'}
                    onClick={() => handleRequestSort('category')}
                  >
                    Category
                  </TableSortLabel>
                </TableCell>
                <TableCell>Content</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleRequestSort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'responseTime'}
                    direction={orderBy === 'responseTime' ? order : 'asc'}
                    onClick={() => handleRequestSort('responseTime')}
                  >
                    Response Time
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSortedRants(metrics.recentRants)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rant) => (
                  <TableRow key={rant._id}>
                    <TableCell>
                      {new Date(rant.date).toLocaleDateString()}{' '}
                      {new Date(rant.date).toLocaleTimeString([], { 
                        hour: '2-digit', 
                        minute: '2-digit'
                      })}
                    </TableCell>
                    <TableCell>{rant.author}</TableCell>
                    <TableCell>{rant.target}</TableCell>
                    <TableCell>{rant.department}</TableCell>
                    <TableCell>{rant.category}</TableCell>
                    <TableCell>
                      <Typography
                        style={{
                          maxWidth: 200,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        title={rant.content}
                      >
                        {rant.content}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <RantStatus status={rant.status} />
                    </TableCell>
                    <TableCell>
                      {rant.responses && rant.responses.length > 0 
                        ? `${Math.round((new Date(rant.responses[0].date) - new Date(rant.date)) / (1000 * 60 * 60 * 24) * 10) / 10} days`
                        : '-'
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            component="div"
            count={metrics.recentRants.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>
      </Paper>
    </Container>
  );
}

export default DepartmentMetrics; 
