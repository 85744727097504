import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Box,
  Avatar,
  Tooltip,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import {
  Search as SearchIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Comment as CommentIcon,
  ThumbUp as ThumbUpIcon
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import KnowledgeBaseCategories from './KnowledgeBaseCategories';
import KnowledgeBaseArticleDetail from './KnowledgeBaseArticleDetail';
import KnowledgeBaseArticleEditor from './KnowledgeBaseArticleEditor';
import api from '../utils/api';
import KnowledgeBaseCategoryStats from './KnowledgeBaseCategoryStats';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  searchBar: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  articleStats: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  statItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  articleMeta: {
    marginTop: theme.spacing(2),
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  dateInfo: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  searchAndFilters: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headerButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  headerButton: {
    whiteSpace: 'nowrap',
  },
  articleCard: {
    backgroundColor: theme.palette.background.paper,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      backgroundColor: theme.palette.background.alternate,
    },
  },
  categoryChip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  tagChip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }
}));

function KnowledgeBase() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('newest');
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [categoryStats, setCategoryStats] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [minRating, setMinRating] = useState(0);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryForm, setCategoryForm] = useState({
    name: '',
    description: ''
  });
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    fetchCategoryStats();
  }, []);

  const fetchCategoryStats = async () => {
    try {
      const response = await api.get('/api/knowledge-base/categories/stats');
      setCategoryStats(response.data);
    } catch (error) {
      console.error('Error fetching category stats:', error);
    }
  };

  const fetchArticles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/knowledge-base/articles', {
        params: {
          category: selectedCategory !== 'all' ? selectedCategory : undefined,
          sort: sortBy,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          minRating
        }
      });
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedCategory, sortBy, dateRange, minRating]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles, selectedCategory, minRating]);

  const handleCreateArticle = () => {
    history.push('/knowledge-base/new-article');
  };

  const handleEditArticle = (article) => {
    history.push(`/knowledge-base/articles/${article._id}/edit`);
  };

  const handleCloseEditor = () => {
    setShowEditor(false);
    setSelectedArticle(null);
    fetchArticles();
  };

  const handleDeleteArticle = async (articleId) => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        await api.delete(`/api/knowledge-base/articles/${articleId}`);
        fetchArticles();
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const handleViewArticle = (articleId) => {
    history.push(`/knowledge-base/articles/${articleId}`);
  };

  const filteredArticles = articles.filter(article =>
    article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    article.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setEditingCategory(null);
    setCategoryForm({ name: '', description: '' });
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setCategoryForm({
      name: category.name,
      description: category.description || ''
    });
    setOpenCategoryDialog(true);
  };

  const handleSaveCategory = async () => {
    try {
      if (editingCategory) {
        await api.put(`/api/knowledge-base/categories/${editingCategory._id}`, categoryForm);
      } else {
        await api.post('/api/knowledge-base/categories', categoryForm);
      }
      fetchCategories();
      handleCloseCategoryDialog();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await api.delete(`/api/knowledge-base/categories/${categoryId}`);
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/knowledge-base/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  if (loading) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (showEditor) {
    return (
      <KnowledgeBaseArticleEditor
        article={selectedArticle}
        onClose={handleCloseEditor}
      />
    );
  }

  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography variant="h4">
              Knowledge Base
            </Typography>
            <div className={classes.headerButtons}>
              {(user?.role === 'manager' || user?.role === 'admin') && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenCategoryDialog}
                    className={classes.headerButton}
                  >
                    Manage Categories
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleCreateArticle}
                  >
                    Create Article
                  </Button>
                </>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.searchAndFilters}>
            <Grid container spacing={2} alignItems="center">
              {/* Search Bar */}
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search articles..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Filters */}
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Sort By</InputLabel>
                      <Select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        label="Sort By"
                      >
                        <MenuItem value="newest">Newest First</MenuItem>
                        <MenuItem value="oldest">Oldest First</MenuItem>
                        <MenuItem value="title">Title A-Z</MenuItem>
                        <MenuItem value="views">Most Viewed</MenuItem>
                        <MenuItem value="rating">Highest Rated</MenuItem>
                        <MenuItem value="comments">Most Comments</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      label="Start Date"
                      value={dateRange.startDate}
                      onChange={date => setDateRange(prev => ({ ...prev, startDate: date }))}
                      KeyboardButtonProps={{
                        'aria-label': 'change start date',
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      label="End Date"
                      value={dateRange.endDate}
                      onChange={date => setDateRange(prev => ({ ...prev, endDate: date }))}
                      KeyboardButtonProps={{
                        'aria-label': 'change end date',
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <KnowledgeBaseCategoryStats stats={categoryStats} />
        </Grid>

        {/* Article List */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {filteredArticles.map((article) => (
              <Grid item xs={12} key={article._id}>
                <Card className={classes.articleCard}>
                  <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="h6" gutterBottom>
                        {article.title}
                      </Typography>
                      <div className={classes.ratingContainer}>
                        <Rating 
                          value={article.averageRating || 0} 
                          precision={0.5} 
                          readOnly 
                          size="small"
                        />
                        <Typography variant="body2" color="textSecondary">
                          ({article.ratings?.length || 0})
                        </Typography>
                      </div>
                    </Box>

                    <Typography variant="body2" color="textSecondary" paragraph>
                      {article.content.substring(0, 200)}...
                    </Typography>

                    <div className={classes.articleMeta}>
                      <div className={classes.authorInfo}>
                        <Avatar>{article.author?.name?.[0]}</Avatar>
                        <div>
                          <Typography variant="subtitle2">
                            {article.author?.name}
                          </Typography>
                          <Typography variant="caption" className={classes.dateInfo}>
                            Created: {new Date(article.createdAt).toLocaleDateString()}
                            {article.updatedAt !== article.createdAt && 
                              ` • Updated: ${new Date(article.updatedAt).toLocaleDateString()}`
                            }
                          </Typography>
                        </div>
                      </div>

                      <div className={classes.articleStats}>
                        <Tooltip title="Views">
                          <div className={classes.statItem}>
                            <VisibilityIcon fontSize="small" />
                            <Typography variant="body2">
                              {article.views || 0}
                            </Typography>
                          </div>
                        </Tooltip>

                        <Tooltip title="Comments">
                          <div className={classes.statItem}>
                            <CommentIcon fontSize="small" />
                            <Typography variant="body2">
                              {article.comments?.length || 0}
                            </Typography>
                          </div>
                        </Tooltip>

                        <Chip
                          label={article.category.name}
                          size="small"
                          className={classes.categoryChip}
                        />
                        {article.tags.map((tag) => (
                          <Chip
                            key={tag}
                            label={tag}
                            size="small"
                            variant="outlined"
                            className={classes.tagChip}
                          />
                        ))}
                      </div>
                    </div>
                  </CardContent>

                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleViewArticle(article._id)}
                      startIcon={<VisibilityIcon />}
                    >
                      Read More
                    </Button>
                    {(user?.role === 'manager' || user?.role === 'admin') && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => handleEditArticle(article)}
                          title="Edit Article"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteArticle(article._id)}
                          title="Delete Article"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Add Category Management Dialog */}
        <Dialog 
          open={openCategoryDialog} 
          onClose={handleCloseCategoryDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {editingCategory ? 'Edit Category' : 'Add Category'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Category Name"
              fullWidth
              value={categoryForm.name}
              onChange={(e) => setCategoryForm({ ...categoryForm, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={categoryForm.description}
              onChange={(e) => setCategoryForm({ ...categoryForm, description: e.target.value })}
            />
            {categories.length > 0 && (
              <>
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                  Existing Categories
                </Typography>
                <List>
                  {categories.map((category) => (
                    <ListItem key={category._id}>
                      <ListItemText
                        primary={category.name}
                        secondary={category.description}
                      />
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end" 
                          onClick={() => handleEditCategory(category)}
                          title="Edit Category"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          edge="end" 
                          onClick={() => handleDeleteCategory(category._id)}
                          title="Delete Category"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCategoryDialog}>
              Cancel
            </Button>
            <Button 
              onClick={handleSaveCategory} 
              color="primary"
              variant="contained"
              disabled={!categoryForm.name.trim()}
            >
              {editingCategory ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Container>
  );
}

export default KnowledgeBase;