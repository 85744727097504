import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  Snackbar,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router-dom';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  formControl: {
    minWidth: 200,
  },
  tagInput: {
    marginTop: theme.spacing(1),
  },
  tagChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  }
}));

function KnowledgeBaseArticleEditor() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: '',
    tags: [],
    tagInput: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchArticle();
  }, [id]);

  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/knowledge-base/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchArticle = useCallback(async () => {
    if (!id) return;
    try {
      const response = await api.get(`/api/knowledge-base/articles/${id}`);
      const { title, content, category, tags } = response.data;
      setFormData({
        title,
        content,
        category: category._id,
        tags,
        tagInput: ''
      });
    } catch (error) {
      console.error('Error fetching article:', error);
      setError('Failed to load article');
    }
  }, [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id) {
      fetchArticle();
    }
  }, [id, fetchArticle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { tagInput, ...submitData } = formData;
      if (id) {
        await api.put(`/api/knowledge-base/articles/${id}`, submitData);
        setSuccessMessage('Article updated successfully!');
      } else {
        await api.post('/api/knowledge-base/articles', submitData);
        setSuccessMessage('Article created successfully!');
      }
      setTimeout(() => {
        history.push('/knowledge-base');
      }, 2000);
    } catch (error) {
      console.error('Error saving article:', error);
      setError('Failed to save article');
    } finally {
      setLoading(false);
    }
  };

  const handleAddTag = (e) => {
    if (e.key === 'Enter' && formData.tagInput.trim()) {
      e.preventDefault();
      if (!formData.tags.includes(formData.tagInput.trim())) {
        setFormData(prev => ({
          ...prev,
          tags: [...prev.tags, prev.tagInput.trim()],
          tagInput: ''
        }));
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  return (
    <Container className={classes.root}>
      <Paper>
        <Box p={3}>
          <Typography variant="h5" gutterBottom>
            {id ? 'Edit Article' : 'Create New Article'}
          </Typography>

          {error && (
            <Typography color="error" gutterBottom>
              {error}
            </Typography>
          )}

          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              label="Title"
              fullWidth
              required
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />

            <FormControl required className={classes.formControl}>
              <InputLabel>Category</InputLabel>
              <Select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Content"
              fullWidth
              required
              multiline
              rows={10}
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
            />

            <div>
              <TextField
                label="Tags (Press Enter to add)"
                fullWidth
                value={formData.tagInput}
                onChange={(e) => setFormData({ ...formData, tagInput: e.target.value })}
                onKeyPress={handleAddTag}
                className={classes.tagInput}
              />
              <div className={classes.tagChips}>
                {formData.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={() => handleRemoveTag(tag)}
                  />
                ))}
              </div>
            </div>

            <div className={classes.actions}>
              <Button
                onClick={() => history.push('/knowledge-base')}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : (id ? 'Update' : 'Create')}
              </Button>
            </div>
          </form>

          <Snackbar
            open={!!successMessage}
            autoHideDuration={2000}
            onClose={() => setSuccessMessage('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert 
              onClose={() => setSuccessMessage('')} 
              severity="success"
              elevation={6}
              variant="filled"
            >
              {successMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Paper>
    </Container>
  );
}

export default KnowledgeBaseArticleEditor; 