import React, { useContext, useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge
} from '@material-ui/core';
import { 
  Dashboard,
  RateReview,
  List as ListIcon,
  Settings,
  Timeline,
  Assignment,
  Book,
  People,
  Person,
  Brightness4,
  Brightness7,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  NotificationsActive as NotificationsActiveIcon,
  NotificationsNone as NotificationsNoneIcon,
  CheckCircle as CheckCircleIcon,
  Comment as CommentIcon,
  Info as InfoIcon,
} from '@material-ui/icons';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Logo from './Logo';
import { io } from 'socket.io-client';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  toolbar: {
    minHeight: 56,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      minHeight: 64,
    },
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
  middleSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  logoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    letterSpacing: '0.5px',
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1),
  },
  menuSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  userSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopMenu: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  userName: {
    fontWeight: 500,
    lineHeight: 1.2,
  },
  userRole: {
    fontSize: '0.75rem',
    color: theme.palette.grey[300],
    textTransform: 'capitalize',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  drawer: {
    width: 260,
    '& .MuiDrawer-paper': {
      width: 260,
      backgroundColor: theme.palette.background.paper,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  activeMenuItem: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  menuItem: {
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  menuIcon: {
    color: theme.palette.primary.contrastText,
    minWidth: 40,
  },
  navIcon: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
  },
  themeIcon: {
    color: theme.palette.primary.contrastText,
  },
  userIcon: {
    color: theme.palette.primary.contrastText,
  },
  settingsIcon: {
    color: theme.palette.primary.contrastText,
  },
  moreIcon: {
    color: theme.palette.primary.contrastText,
  },
  navButton: {
    color: theme.palette.primary.contrastText,
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
  menuText: {
    color: theme.palette.text.primary,
  },
  divider: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.divider,
  },
  notificationBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.error.main,
      animation: '$pulse 2s infinite',
      boxShadow: '0 0 0 0 rgba(255, 0, 0, 1)',
      transform: 'scale(1)',
    }
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(255, 0, 0, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(255, 0, 0, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(255, 0, 0, 0)',
    }
  },
  notificationBadgeRead: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.grey[500],
    }
  },
  notificationMenuItem: {
    maxWidth: 360,
    whiteSpace: 'normal',
    '&.unread': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },
  notificationIcon: {
    marginRight: theme.spacing(1),
  },
  notificationDot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  notificationHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function Navigation() {
  const classes = useStyles();
  const { user, logout } = useContext(AuthContext);
  const { isDarkMode, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      fetchNotifications();
      const socket = io(process.env.REACT_APP_API_URL || 'https://api.rantanalyzer.com');
      socket.emit('join', user.id);
      
      socket.on('notification', (newNotification) => {
        setNotifications(prev => [newNotification, ...prev]);
        if (!newNotification.read) {
          setUnreadCount(prev => prev + 1);
        }
      });

      return () => socket.disconnect();
    }
  }, [user]);

  const getMenuIcon = (text) => {
    const iconProps = { className: classes.navIcon };
    switch (text) {
      case 'Dashboard': return <Dashboard {...iconProps} />;
      case 'Submit Rant': return <RateReview {...iconProps} />;
      case 'All Rants': return <ListIcon {...iconProps} />;
      case 'Manage Options': return <Settings {...iconProps} />;
      case 'Department Metrics': return <Timeline {...iconProps} />;
      case 'Action Plans': return <Assignment {...iconProps} />;
      case 'Knowledge Base': return <Book {...iconProps} />;
      case 'User Management': return <People {...iconProps} />;
      default: return <ListIcon {...iconProps} />;
    }
  };

  const getMenuItems = () => {
    if (!user) return [];

    if (user.role === 'admin') {
      return [
        { text: 'Dashboard', path: '/manager-dashboard' },
        { text: 'Submit Rant', path: '/rant' },
        { text: 'All Rants', path: '/rants' },
        { text: 'Manage Options', path: '/manage-options' },
        { text: 'Department Metrics', path: '/department-metrics' },
        { text: 'Action Plans', path: '/action-plans' },
        { text: 'User Management', path: '/user-management' },
        { text: 'Knowledge Base', path: '/knowledge-base' }
      ];
    }

    if (user.role === 'manager') {
      return [
        { text: 'Dashboard', path: '/manager-dashboard' },
        { text: 'Submit Rant', path: '/rant' },
        { text: 'All Rants', path: '/rants' },
        { text: 'Manage Options', path: '/manage-options' },
        { text: 'Department Metrics', path: '/department-metrics' },
        { text: 'Action Plans', path: '/action-plans' },
        { text: 'User Management', path: '/user-management' },
        { text: 'Knowledge Base', path: '/knowledge-base' }
      ];
    }

    // Employee menu items
    return [
      { text: 'Dashboard', path: '/employee-dashboard' },
      { text: 'Submit Rant', path: '/rant' },
      { text: 'All Rants', path: '/rants' },
      { text: 'Knowledge Base', path: '/knowledge-base' }
    ];
  };

  const menuItems = getMenuItems();

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    handleClose();
    logout();
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchor(event.currentTarget);
  };

  const handleNotificationItemClick = async (notification) => {
    try {
      if (!notification.read) {
        await api.put(`/api/auth/notifications/${notification._id}/read`);
        setNotifications(prev => 
          prev.map(n => n._id === notification._id ? { ...n, read: true } : n)
        );
        setUnreadCount(prev => Math.max(0, prev - 1));
      }
      
      // Navigate to the related content
      if (notification.relatedModel === 'Rant' && notification.relatedId) {
        history.push(`/rant/${notification.relatedId}`);
      }
      handleNotificationClose();
    } catch (error) {
      console.error('Error handling notification click:', error);
    }
  };

  const handleNotificationClose = () => {
    setNotificationAnchor(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      await api.put('/api/auth/notifications/mark-all-read');
      setNotifications(prev => prev.map(n => ({ ...n, read: true })));
      setUnreadCount(0);
      handleNotificationClose();
    } catch (error) {
      console.error('Error marking all as read:', error);
    }
  };

  const getNotificationIcon = (type, priority) => {
    switch (type) {
      case 'rant':
        return priority === 'high' ? (
          <NotificationsActiveIcon color="error" />
        ) : (
          <NotificationsIcon color="primary" />
        );
      case 'response':
        return <CommentIcon color="primary" />;
      case 'system':
        return <InfoIcon color="primary" />;
      default:
        return <NotificationsIcon />;
    }
  };

  const renderMenuItems = (isMobile = false) => (
    menuItems.map((item) => {
      const isActive = location.pathname === item.path;
      const MenuItem = isMobile ? ListItem : Button;

      return (
        <MenuItem
          key={item.text}
          component={RouterLink}
          to={item.path}
          className={`${classes.menuItem} ${isActive ? classes.activeMenuItem : ''}`}
          color={isMobile ? 'default' : 'inherit'}
        >
          <ListItemIcon className={classes.menuIcon}>
            {getMenuIcon(item.text)}
          </ListItemIcon>
          <ListItemText 
            primary={item.text} 
            className={classes.menuText}
          />
        </MenuItem>
      );
    })
  );

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/api/notifications');
      setNotifications(response.data);
      setUnreadCount(response.data.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  return (
    <>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftSection}>
            {user && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Logo />
          </div>

          {user && (
            <div className={classes.middleSection}>
              {renderMenuItems()}
            </div>
          )}

          <div className={classes.rightSection}>
            {user ? (
              <>
                <Tooltip title="Notifications">
                  <IconButton
                    color="inherit"
                    onClick={handleNotificationClick}
                    className={classes.iconButton}
                  >
                    <Badge 
                      badgeContent={unreadCount} 
                      color="secondary"
                      overlap="rectangular"
                      className={classes.notificationBadge}
                    >
                      {unreadCount > 0 ? (
                        <NotificationsActiveIcon />
                      ) : (
                        <NotificationsNoneIcon />
                      )}
                    </Badge>
                  </IconButton>
                </Tooltip>

                <IconButton
                  color="inherit"
                  onClick={toggleTheme}
                  className={classes.iconButton}
                >
                  {isDarkMode ? (
                    <Brightness7 className={classes.themeIcon} />
                  ) : (
                    <Brightness4 className={classes.themeIcon} />
                  )}
                </IconButton>

                <div className={classes.userInfo}>
                  <div className={classes.userDetails}>
                    <Typography variant="body1" className={classes.userName}>
                      {user.name}
                    </Typography>
                    <Typography variant="body2" className={classes.userRole}>
                      {user.role}
                    </Typography>
                  </div>
                  <Avatar 
                    className={classes.avatar}
                    onClick={handleMenu}
                  >
                    {user.name ? user.name[0].toUpperCase() : 'U'}
                  </Avatar>
                </div>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem component={RouterLink} to="/profile" onClick={handleClose}>
                    <ListItemIcon>
                      <Person fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button color="inherit" component={RouterLink} to="/login">
                  Login
                </Button>
                <Button color="inherit" component={RouterLink} to="/register">
                  Register
                </Button>
                <IconButton
                  color="inherit"
                  onClick={toggleTheme}
                  className={classes.iconButton}
                >
                  {isDarkMode ? (
                    <Brightness7 className={classes.themeIcon} />
                  ) : (
                    <Brightness4 className={classes.themeIcon} />
                  )}
                </IconButton>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={classes.drawer}
        classes={{
          paper: classes.drawer,
        }}
      >
        <Toolbar />
        <List>
          {renderMenuItems(true)}
        </List>
      </Drawer>

      {user && (
        <Menu
          anchorEl={notificationAnchor}
          keepMounted
          open={Boolean(notificationAnchor)}
          onClose={handleNotificationClose}
          PaperProps={{
            style: {
              maxHeight: 400,
              width: 360,
            },
          }}
        >
          <div className={classes.notificationHeader}>
            <Typography variant="h6">Notifications</Typography>
            {unreadCount > 0 && (
              <Button 
                size="small" 
                onClick={handleMarkAllAsRead}
                startIcon={<CheckCircleIcon />}
              >
                Mark all as read
              </Button>
            )}
          </div>
          {notifications.filter(n => !n.read).length > 0 ? (
            notifications
              .filter(n => !n.read)
              .map((notification) => (
                <MenuItem
                  key={notification._id}
                  onClick={() => handleNotificationItemClick(notification)}
                  className={classes.notificationMenuItem}
                >
                  <ListItemIcon>
                    {getNotificationIcon(notification.type, notification.priority)}
                  </ListItemIcon>
                  <ListItemText
                    primary={notification.title}
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {notification.message}
                      </Typography>
                    }
                  />
                </MenuItem>
              ))
          ) : (
            <MenuItem disabled>
              <ListItemText primary="No new notifications" />
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
}

export default Navigation;
