import React, { useState, useContext } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Box,
  IconButton,
  List,
  // eslint-disable-next-line no-unused-vars
  ListItem,
  ListItemAvatar,
  // eslint-disable-next-line no-unused-vars
  ListItemText,
  makeStyles
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { AuthContext } from '../context/AuthContext';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  commentForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  commentActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  commentItem: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  commentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentDate: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  editForm: {
    marginTop: theme.spacing(1),
  }
}));

function KnowledgeBaseComment({ articleId, comments, onCommentUpdate }) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [newComment, setNewComment] = useState('');
  const [editingComment, setEditingComment] = useState(null);
  const [editContent, setEditContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`/api/knowledge-base/articles/${articleId}/comments`, {
        content: newComment
      });
      setNewComment('');
      onCommentUpdate(response.data);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleEdit = async (commentId) => {
    try {
      const response = await api.put(
        `/api/knowledge-base/articles/${articleId}/comments/${commentId}`,
        { content: editContent }
      );
      setEditingComment(null);
      setEditContent('');
      onCommentUpdate(response.data);
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const handleDelete = async (commentId) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      try {
        const response = await api.delete(
          `/api/knowledge-base/articles/${articleId}/comments/${commentId}`
        );
        onCommentUpdate(response.data);
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Comments ({comments.length})
      </Typography>

      <Paper className={classes.commentForm}>
        <Box p={2}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <div className={classes.commentActions}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!newComment.trim()}
              >
                Add Comment
              </Button>
            </div>
          </form>
        </Box>
      </Paper>

      <List>
        {comments.map((comment) => (
          <Paper key={comment._id} className={classes.commentItem}>
            <div className={classes.commentHeader}>
              <ListItemAvatar>
                <Avatar>{comment.user.name[0]}</Avatar>
              </ListItemAvatar>
              <Typography variant="subtitle2">
                {comment.user.name}
              </Typography>
              <Typography className={classes.commentDate}>
                {new Date(comment.createdAt).toLocaleString()}
              </Typography>
              {user.id === comment.user._id && (
                <div>
                  <IconButton size="small" onClick={() => {
                    setEditingComment(comment._id);
                    setEditContent(comment.content);
                  }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDelete(comment._id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </div>
            
            {editingComment === comment._id ? (
              <form 
                className={classes.editForm}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEdit(comment._id);
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                />
                <div className={classes.commentActions}>
                  <Button
                    size="small"
                    onClick={() => setEditingComment(null)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="small"
                    disabled={!editContent.trim()}
                  >
                    Save
                  </Button>
                </div>
              </form>
            ) : (
              <Typography variant="body1">
                {comment.content}
              </Typography>
            )}
          </Paper>
        ))}
      </List>
    </div>
  );
}

export default KnowledgeBaseComment; 