import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.apiUrl,  // Use the config value instead of hardcoded URL
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
