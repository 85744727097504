import React, { createContext, useState, useEffect } from 'react';
import api from '../utils/api';
import { initializeSocket, closeSocket } from '../services/socketService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        api.defaults.headers.common['x-auth-token'] = token;
        try {
          const res = await api.get('/api/auth');
          setUser(res.data);
        } catch (err) {
          console.error('Error loading user:', err);
          localStorage.removeItem('token');
        }
      }
      setLoading(false);
    };

    loadUser();
  }, []);

  useEffect(() => {
    if (user) {
      initializeSocket();
    } else {
      closeSocket();
    }
  }, [user]);

  const login = async (token) => {
    localStorage.setItem('token', token);
    api.defaults.headers.common['x-auth-token'] = token;
    try {
      const res = await api.get('/api/auth');
      setUser(res.data);
      console.log('User set in context:', res.data);
    } catch (err) {
      console.error('Error setting user:', err);
      localStorage.removeItem('token');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['x-auth-token'];
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
