import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { TextField, Button, Typography, Container, Paper, makeStyles, MenuItem } from '@material-ui/core';
import Notification from './Notification';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
  },
  helperText: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  }
}));

function RantForm() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    target: '',
    department: '',
    categoryType: '',
    content: ''
  });
  const [notification, setNotification] = useState({
    open: false,
    severity: 'success',
    message: ''
  });
  const [targetEmployees, setTargetEmployees] = useState([]);
  const [targetDepartments, setTargetDepartments] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [employeesRes, departmentsRes, categoriesRes] = await Promise.all([
          api.get('/api/manage/targetEmployees'),
          api.get('/api/manage/targetDepartments'),
          api.get('/api/manage/categoryTypes')
        ]);
        setTargetEmployees(employeesRes.data);
        setTargetDepartments(departmentsRes.data);
        setCategoryTypes(categoriesRes.data);
      } catch (err) {
        console.error('Error fetching options:', err);
      }
    };
    fetchOptions();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting rant:', formData);
      const res = await api.post('/api/rants', {
        target: formData.target,
        department: formData.department,
        categoryType: formData.categoryType,
        content: formData.content
      });
      console.log('Rant submission response:', res.data);
      setNotification({
        open: true,
        severity: 'success',
        message: 'Rant submitted successfully!'
      });
      setFormData({ target: '', department: '', categoryType: '', content: '' });
    } catch (err) {
      console.error('Error submitting rant:', err.response?.data || err.message);
      setNotification({
        open: true,
        severity: 'error',
        message: err.response?.data?.msg || 'An error occurred while submitting the rant.'
      });
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>Submit a Rant</Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            select
            name="target"
            label="Target Employee"
            value={formData.target}
            onChange={handleChange}
            required
            fullWidth
          >
            {targetEmployees.map((employee) => (
              <MenuItem key={employee._id} value={employee._id}>
                {employee.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            name="department"
            label="Target Department"
            value={formData.department}
            onChange={handleChange}
            required
            fullWidth
          >
            {targetDepartments.map((department) => (
              <MenuItem key={department._id} value={department._id}>
                {department.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            name="categoryType"
            label="Category Type"
            value={formData.categoryType}
            onChange={handleChange}
            required
            fullWidth
          >
            {categoryTypes.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="content"
            label="Rant Content"
            multiline
            rows={4}
            value={formData.content}
            onChange={handleChange}
            required
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            Submit Rant
          </Button>
        </form>
      </Paper>
      <Notification
        open={notification.open}
        handleClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />
    </Container>
  );
}

export default RantForm;
