import React, { useState, useEffect, useContext } from 'react';
import {
  Paper,
  Typography,
  Box,
  LinearProgress,
  makeStyles
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Star as StarIcon } from '@material-ui/icons';
import { AuthContext } from '../context/AuthContext';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  ratingBox: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  ratingStats: {
    marginTop: theme.spacing(2),
  },
  ratingBar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ratingCount: {
    minWidth: '3em',
    textAlign: 'right',
  },
  progress: {
    flexGrow: 1,
  },
  averageRating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  bigRating: {
    fontSize: '2rem',
    fontWeight: 'bold',
  }
}));

function KnowledgeBaseRating({ articleId, ratings, onRatingUpdate }) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [userRating, setUserRating] = useState(0);

  useEffect(() => {
    const currentUserRating = ratings.find(r => r.user === user.id);
    if (currentUserRating) {
      setUserRating(currentUserRating.score);
    }
  }, [ratings, user.id]);

  const handleRating = async (value) => {
    try {
      const response = await api.post(`/api/knowledge-base/articles/${articleId}/rate`, {
        score: value
      });
      onRatingUpdate(response.data);
    } catch (error) {
      console.error('Error rating article:', error);
    }
  };

  const calculateRatingStats = () => {
    const stats = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    ratings.forEach(rating => {
      stats[rating.score] = (stats[rating.score] || 0) + 1;
    });
    return stats;
  };

  const ratingStats = calculateRatingStats();
  const averageRating = ratings.length
    ? ratings.reduce((acc, curr) => acc + curr.score, 0) / ratings.length
    : 0;

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Ratings & Reviews
      </Typography>

      <Box className={classes.ratingBox}>
        <Box className={classes.averageRating}>
          <Typography className={classes.bigRating}>
            {averageRating.toFixed(1)}
          </Typography>
          <Rating
            value={averageRating}
            precision={0.5}
            readOnly
          />
          <Typography variant="caption">
            {ratings.length} ratings
          </Typography>
        </Box>

        <Box className={classes.ratingStats}>
          {[5, 4, 3, 2, 1].map((score) => (
            <div key={score} className={classes.ratingBar}>
              <Typography variant="body2">{score}</Typography>
              <StarIcon fontSize="small" />
              <LinearProgress
                className={classes.progress}
                variant="determinate"
                value={(ratingStats[score] / ratings.length) * 100 || 0}
              />
              <Typography className={classes.ratingCount} variant="body2">
                {ratingStats[score]}
              </Typography>
            </div>
          ))}
        </Box>
      </Box>

      <Box mt={3}>
        <Typography gutterBottom>Rate this article:</Typography>
        <Rating
          value={userRating}
          onChange={(event, newValue) => {
            setUserRating(newValue);
            handleRating(newValue);
          }}
        />
      </Box>
    </Paper>
  );
}

export default KnowledgeBaseRating; 