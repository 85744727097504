import React, { useState, useEffect, useContext } from 'react';
import api from '../utils/api';
import { useParams } from 'react-router-dom';
import { 
  Typography, 
  TextField, 
  Button, 
  Card, 
  CardContent, 
  Snackbar, 
  CircularProgress, 
  Container, 
  Paper,
  makeStyles 
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AuthContext } from '../context/AuthContext';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  responseInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  responseSection: {
    marginTop: theme.spacing(4),
  },
  responseCard: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  }
}));

function RantDetail() {
  const classes = useStyles();
  const [rant, setRant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchRant = async () => {
      try {
        setLoading(true);
        const res = await api.get(`/api/rants/${id}`);
        setRant(res.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching rant:', err);
        setError('Failed to load rant. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRant();
  }, [id]);

  const handleSubmitResponse = async (e) => {
    e.preventDefault();
    try {
      if (!response.trim()) {
        enqueueSnackbar('Response cannot be empty', { variant: 'error' });
        return;
      }

      const res = await api.post(`/api/rants/${id}/respond`, { 
        response,
        responderName: user.name
      });

      setRant(res.data);
      setResponse('');
      enqueueSnackbar('Response submitted successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error submitting response:', error);
      enqueueSnackbar(error.response?.data?.msg || 'Error submitting response', { 
        variant: 'error' 
      });
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!rant) return <Typography>Rant not found.</Typography>;

  return (
    <Container maxWidth="md" className={classes.root}>
      <Paper elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
        <Typography variant="h4" gutterBottom>Rant Detail</Typography>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">{rant.target.name} - {rant.department.name}</Typography>
            <Typography variant="body1">{rant.content}</Typography>
            <Typography variant="caption">
              Posted by {rant.author.name} on: {new Date(rant.date).toLocaleString()}
            </Typography>
            <Typography variant="subtitle2">Category: {rant.categoryType.name}</Typography>
          </CardContent>
        </Card>

        {rant.responses && rant.responses.map((response, index) => (
          <Card key={index} className={classes.responseCard}>
            <CardContent>
              <Typography variant="body1">{response.content}</Typography>
              <Typography variant="caption" color="textSecondary">
                Responded by {response.responder?.name || 'Unknown'} on {' '}
                {new Date(response.date).toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        ))}

        {(user?.role === 'manager' || user?.role === 'admin') && (
          <form onSubmit={handleSubmitResponse} className={classes.responseSection}>
            <TextField
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              variant="outlined"
              placeholder="Write your response..."
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              className={classes.responseInput}
            />
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={!response.trim()}
            >
              Submit Response
            </Button>
          </form>
        )}

        <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
          <Alert onClose={() => setSuccessMessage('')} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default RantDetail;
