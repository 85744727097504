import React, { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { AccessTime as ClockIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  clockContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  clockIcon: {
    fontSize: '1.2rem',
    color: theme.palette.primary.contrastText,
  },
  clockText: {
    fontFamily: 'monospace',
    fontSize: '0.875rem',
    color: theme.palette.primary.contrastText,
  }
}));

function Clock() {
  const classes = useStyles();
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={classes.clockContainer}>
      <ClockIcon className={classes.clockIcon} />
      <Typography className={classes.clockText}>
        {time.toLocaleDateString()} {time.toLocaleTimeString()}
      </Typography>
    </div>
  );
}

export default Clock; 