import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { NotificationProvider } from './context/NotificationContext';
import { ThemeProvider } from './context/ThemeContext';
import { CssBaseline, makeStyles } from '@material-ui/core';
import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import ManagerDashboard from './components/ManagerDashboard';
import EmployeeDashboard from './components/EmployeeDashboard';
import Login from './components/Login';
import Register from './components/Register';
import RantForm from './components/RantForm';
import RantDetail from './components/RantDetail';
import RantList from './components/RantList';
import PrivateRoute from './components/PrivateRoute';
import ManageOptions from './components/ManageOptions';
import UserProfile from './components/UserProfile';
import UserManagement from './components/UserManagement';
import DepartmentMetrics from './components/DepartmentMetrics';
import ActionPlans from './components/ActionPlans';
import {
  KnowledgeBase,
  KnowledgeBaseArticleDetail,
  KnowledgeBaseArticleEditor
} from './components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
  }
}));

function App() {
  const classes = useStyles();

  return (
    <SnackbarProvider maxSnack={3}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AuthProvider>
          <ThemeProvider>
            <NotificationProvider>
              <Router>
                <div className={classes.root}>
                  <CssBaseline />
                  <Navigation />
                  <main className={classes.main}>
                    <Switch>
                      <PrivateRoute path="/" exact component={Dashboard} />
                      <PrivateRoute path="/manager-dashboard" component={ManagerDashboard} roles={['manager', 'admin']} />
                      <PrivateRoute 
                        path="/employee-dashboard" 
                        component={EmployeeDashboard}
                        roles={['employee']}
                      />
                      <Route path="/login" component={Login} />
                      <Route path="/register" component={Register} />
                      <PrivateRoute path="/rant" exact component={RantForm} />
                      <PrivateRoute path="/rant/:id" component={RantDetail} />
                      <PrivateRoute path="/rants" exact component={RantList} />
                      <PrivateRoute path="/manage-options" component={ManageOptions} roles={['manager']} />
                      <PrivateRoute path="/profile" component={UserProfile} />
                      <PrivateRoute 
                        path="/user-management" 
                        component={UserManagement}
                        roles={['manager', 'admin']}
                      />
                      <PrivateRoute 
                        exact
                        path="/knowledge-base" 
                        component={KnowledgeBase}
                      />
                      <PrivateRoute 
                        exact
                        path="/knowledge-base/articles/:id" 
                        component={KnowledgeBaseArticleDetail}
                      />
                      <PrivateRoute 
                        path="/knowledge-base/articles/:id/edit" 
                        component={KnowledgeBaseArticleEditor}
                        roles={['manager', 'admin']}
                      />
                      <PrivateRoute 
                        path="/knowledge-base/new-article" 
                        component={KnowledgeBaseArticleEditor}
                        roles={['manager', 'admin']}
                      />
                      <PrivateRoute 
                        path="/department-metrics" 
                        component={DepartmentMetrics} 
                        roles={['manager', 'admin']}
                      />
                      <PrivateRoute 
                        path="/action-plans" 
                        component={ActionPlans} 
                        roles={['manager', 'admin']}
                      />
                      <Route path="/privacy" component={PrivacyPolicy} />
                      <Route path="/terms" component={TermsOfService} />
                    </Switch>
                  </main>
                  <Footer />
                </div>
              </Router>
            </NotificationProvider>
          </ThemeProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
}

export default App;
