import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../utils/api';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  makeStyles,
  Grid
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Notification from './Notification';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    maxWidth: 400,
    margin: '0 auto',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  }
}));

function Register() {
  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      setNotification({
        open: true,
        message: 'Passwords do not match',
        severity: 'error'
      });
      return;
    }

    try {
      // Send registration request with default role 'employee'
      const registerData = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: 'employee' // Set default role
      };

      await api.post('/api/auth/register', registerData);
      
      setNotification({
        open: true,
        message: 'Registration successful! Your account is pending admin approval. You will receive an email once your account is approved.',
        severity: 'success'
      });

      // Redirect to login after successful registration
      setTimeout(() => {
        history.push('/login');
      }, 5000);
    } catch (err) {
      setNotification({
        open: true,
        message: err.response?.data?.msg || 'Registration failed',
        severity: 'error'
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h4" gutterBottom>
          Register
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            className={classes.textField}
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            className={classes.textField}
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            className={classes.textField}
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            className={classes.textField}
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            fullWidth
          />
          <MuiAlert severity="info" sx={{ mt: 2, mb: 2 }}>
            Note: New accounts require administrator approval before access is granted. 
            You will receive an email notification once your account is approved.
          </MuiAlert>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
          >
            Register
          </Button>
        </form>
      </Paper>
      <Notification
        open={notification.open}
        handleClose={() => setNotification({ ...notification, open: false })}
        severity={notification.severity}
        message={notification.message}
      />
    </Container>
  );
}

export default Register;
