import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { Grid, Card, CardContent, Typography, Paper, Container } from '@material-ui/core';
import { Bar, Pie, Line } from 'react-chartjs-2';
import RantList from './RantList';

function Dashboard() {
  const [rantStats, setRantStats] = useState({
    totalRants: 0,
    topDepartments: [],
    topEmployees: [],
    rantsByMonth: []
  });

  useEffect(() => {
    const fetchRantStats = async () => {
      try {
        const res = await api.get('/api/rants/stats');
        setRantStats(res.data);
      } catch (err) {
        console.error('Error fetching rant stats:', err);
      }
    };

    fetchRantStats();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h5">Total Rants</Typography>
                <Typography variant="h3">{rantStats.totalRants}</Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h5">Top Departments</Typography>
                <Pie
                  data={{
                    labels: rantStats.topDepartments.map(d => d.name),
                    datasets: [{
                      data: rantStats.topDepartments.map(d => d.count),
                      backgroundColor: [
                        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
                      ]
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h5">Top Employees</Typography>
                <Bar
                  data={{
                    labels: rantStats.topEmployees.map(e => e.name),
                    datasets: [{
                      label: 'Rant Count',
                      data: rantStats.topEmployees.map(e => e.count),
                      backgroundColor: 'rgba(75,192,192,0.6)'
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h5">Rants by Month</Typography>
                <Line
                  data={{
                    labels: rantStats.rantsByMonth.map(m => m._id),
                    datasets: [{
                      label: 'Rant Count',
                      data: rantStats.rantsByMonth.map(m => m.count),
                      borderColor: 'rgba(75,192,192,1)',
                      fill: false
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <RantList />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
