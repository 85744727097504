import React, { useState, useContext } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Avatar,
  Divider,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { AuthContext } from '../context/AuthContext';
import api from '../utils/api';
import { NotificationContext, TOAST_POSITIONS } from '../context/NotificationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    fontSize: '2.5rem',
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  value: {
    marginTop: theme.spacing(1),
  },
  editButton: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  }
}));

function UserProfile() {
  const classes = useStyles();
  const { user, login } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { toastPosition, changeToastPosition } = useContext(NotificationContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.newPassword && formData.newPassword !== formData.confirmPassword) {
        setError('New passwords do not match');
        return;
      }

      const response = await api.put('/api/auth/profile', {
        name: formData.name,
        email: formData.email,
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      });

      setSuccess('Profile updated successfully');
      setIsEditing(false);
      
      // Update the user context if needed
      if (response.data.token) {
        login(response.data.token);
      }
    } catch (err) {
      setError(err.response?.data?.msg || 'Error updating profile');
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Paper elevation={3}>
        <Card>
          <CardContent>
            <Grid container direction="column" alignItems="center" className={classes.section}>
              <Avatar className={classes.avatar}>
                {user?.name?.[0]?.toUpperCase()}
              </Avatar>
              <Typography variant="h4" gutterBottom>
                User Profile
              </Typography>
            </Grid>

            <Divider />

            {!isEditing ? (
              // View Mode
              <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Name
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {user?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Email
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {user?.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Role
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsEditing(true)}
                    className={classes.editButton}
                  >
                    Edit Profile
                  </Button>
                </Grid>
              </Grid>
            ) : (
              // Edit Mode
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={classes.formField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={classes.formField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      value={formData.currentPassword}
                      onChange={handleChange}
                      className={classes.formField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="New Password (optional)"
                      name="newPassword"
                      type="password"
                      value={formData.newPassword}
                      onChange={handleChange}
                      className={classes.formField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Confirm New Password"
                      name="confirmPassword"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className={classes.formField}
                    />
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Typography color="error">{error}</Typography>
                    </Grid>
                  )}
                  {success && (
                    <Grid item xs={12}>
                      <Typography color="primary">{success}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.editButton}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setIsEditing(false)}
                      className={classes.editButton}
                      style={{ marginLeft: '8px' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
            <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
              <InputLabel>Notification Position</InputLabel>
              <Select
                value={toastPosition}
                onChange={(e) => changeToastPosition(e.target.value)}
                label="Notification Position"
              >
                <MenuItem value="TOP_RIGHT">Top Right</MenuItem>
                <MenuItem value="BOTTOM_RIGHT">Bottom Right</MenuItem>
                <MenuItem value="TOP_LEFT">Top Left</MenuItem>
                <MenuItem value="BOTTOM_LEFT">Bottom Left</MenuItem>
                <MenuItem value="TOP_CENTER">Top Center</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
}

export default UserProfile; 