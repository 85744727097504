import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Chip,
  Badge,
  Tooltip,
  makeStyles,
  Switch,
  InputAdornment,
  Box,
  alpha
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Comment as CommentIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Search as SearchIcon,
  Cancel as CancelIcon,
  Block as BlockIcon
} from '@material-ui/icons';
import api from '../utils/api';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../context/AuthContext';
import { NotificationContext } from '../context/NotificationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  badge: {
    marginRight: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  statsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  resolvedChip: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  pendingChip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  searchBox: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  sortButton: {
    minWidth: 40,
    padding: theme.spacing(1),
  },
  tableHeader: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  roleChip: {
    fontWeight: 500,
    padding: theme.spacing(0.5, 1.5),
  },
  adminChip: {
    backgroundColor: theme.palette.type === 'dark' 
      ? alpha(theme.palette.error.main, 0.2)
      : alpha(theme.palette.error.light, 0.2),
    color: theme.palette.error.main,
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  managerChip: {
    backgroundColor: theme.palette.type === 'dark'
      ? alpha(theme.palette.primary.main, 0.2)
      : alpha(theme.palette.primary.light, 0.2),
    color: theme.palette.primary.main,
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  employeeChip: {
    backgroundColor: theme.palette.type === 'dark'
      ? alpha(theme.palette.success.main, 0.2)
      : alpha(theme.palette.success.light, 0.2),
    color: theme.palette.success.main,
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  approveButton: {
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.dark,
    }
  },
  rejectButton: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    }
  },
  statusChip: {
    marginRight: theme.spacing(1)
  }
}));

function UserManagement() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [userStats, setUserStats] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'employee'
  });
  const [error, setError] = useState('');
  const [activeManagerCount, setActiveManagerCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { user: currentUser } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [statusFilter, setStatusFilter] = useState('all');
  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    fetchUsers();
    fetchUserStats();
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await api.get('/api/auth/users');
      setUsers(res.data);
    } catch (err) {
      console.error('Error fetching users:', err);
    }
  };

  const fetchUserStats = async () => {
    try {
      console.log('Fetching user stats...');
      const res = await api.get('/api/auth/users/stats');
      console.log('Received stats:', res.data);
      setUserStats(res.data);
    } catch (err) {
      console.error('Error fetching user stats:', err);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchUsers();
      await fetchUserStats();
    };
    loadData();
  }, []);

  const getUserStats = (userId) => {
    const stats = userStats[userId] || { total: 0, resolved: 0, pending: 0 };
    console.log(`Stats for user ${userId}:`, stats);
    return stats;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (user = null) => {
    if (user) {
      setSelectedUser(user);
      setFormData({
        name: user.name,
        email: user.email,
        password: '',
        role: 'employee'
      });
    } else {
      setSelectedUser(null);
      setFormData({
        name: '',
        email: '',
        password: '',
        role: 'employee'
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setFormData({
      name: '',
      email: '',
      password: '',
      role: 'employee'
    });
    setError('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedUser) {
        await api.put(`/api/auth/users/${selectedUser._id}`, formData);
      } else {
        await api.post('/api/auth/users', formData);
      }
      fetchUsers();
      handleCloseDialog();
    } catch (err) {
      setError(err.response?.data?.msg || 'An error occurred');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await api.delete(`/api/auth/users/${userId}`);
        setError('');
        fetchUsers();
      } catch (err) {
        const errorMessage = err.response?.data?.msg || 'Error deleting user';
        setError(errorMessage);
        alert(errorMessage);
      }
    }
  };

  const handleToggleStatus = async (user) => {
    try {
      await api.put(`/api/auth/users/${user._id}/toggle-status`);
      
      setUsers(users.map(u => 
        u._id === user._id 
          ? { ...u, isActive: !u.isActive }
          : u
      ));

      enqueueSnackbar(
        `User ${user.name} has been ${!user.isActive ? 'enabled' : 'disabled'}`, 
        { variant: 'success' }
      );
    } catch (error) {
      enqueueSnackbar(
        error.response?.data?.msg || 'Error updating user status', 
        { variant: 'error' }
      );
    }
  };

  useEffect(() => {
    const managerCount = users.filter(
      user => user.role === 'manager' && user.isActive
    ).length;
    setActiveManagerCount(managerCount);
  }, [users]);

  const handleRoleChange = (event) => {
    if (event.target.value === 'admin' && !selectedUser?.isAdmin) {
      enqueueSnackbar('Only admins can create admin accounts', { variant: 'error' });
      return;
    }
    setFormData({ ...formData, role: event.target.value });
  };

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const getFilteredAndSortedUsers = () => {
    return users
      .filter(user => {
        const matchesSearch = user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                             user.email.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesRole = statusFilter === 'all' || user.status === statusFilter;
        
        return matchesSearch && matchesRole;
      })
      .sort((a, b) => {
        const multiplier = sortOrder === 'asc' ? 1 : -1;
        if (sortBy === 'name') {
          return multiplier * a.name.localeCompare(b.name);
        }
        if (sortBy === 'email') {
          return multiplier * a.email.localeCompare(b.email);
        }
        if (sortBy === 'role') {
          return multiplier * a.role.localeCompare(b.role);
        }
        if (sortBy === 'lastLogin') {
          const dateA = a.lastLogin ? new Date(a.lastLogin) : new Date(0);
          const dateB = b.lastLogin ? new Date(b.lastLogin) : new Date(0);
          return multiplier * (dateA - dateB);
        }
        return 0;
      });
  };

  const handleApproveUser = async (userId) => {
    try {
      await api.put(`/api/auth/users/${userId}/approve`);
      fetchUsers();
      setNotification({
        type: 'success',
        message: 'User approved successfully'
      });
    } catch (error) {
      console.error('Error approving user:', error);
      setNotification({
        type: 'error',
        message: 'Error approving user'
      });
    }
  };

  const handleRejectUser = async (userId) => {
    try {
      await api.put(`/api/auth/users/${userId}/reject`);
      fetchUsers();
      setNotification({
        type: 'info',
        message: 'User rejected'
      });
    } catch (error) {
      console.error('Error rejecting user:', error);
      setNotification({
        type: 'error',
        message: 'Error rejecting user'
      });
    }
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h4" gutterBottom>User Management</Typography>
      
      <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          placeholder="Search users..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ width: 300 }}
        />
        
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              displayEmpty
            >
              <MenuItem value="name">Sort by Name</MenuItem>
              <MenuItem value="email">Sort by Email</MenuItem>
              <MenuItem value="role">Sort by Role</MenuItem>
              <MenuItem value="lastLogin">Sort by Last Login</MenuItem>
            </Select>
          </FormControl>
          
          <Button
            variant="outlined"
            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
          >
            {sortOrder === 'asc' ? '↑' : '↓'}
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
          >
            Add New User
          </Button>
        </Box>
      </Box>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                  Name {sortBy === 'name' && (sortOrder === 'asc' ? '↑' : '↓')}
                </TableCell>
                <TableCell onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                  Email {sortBy === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
                </TableCell>
                <TableCell onClick={() => handleSort('role')} style={{ cursor: 'pointer' }}>
                  Role {sortBy === 'role' && (sortOrder === 'asc' ? '↑' : '↓')}
                </TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Rant Statistics</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getFilteredAndSortedUsers()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => {
                  const stats = getUserStats(user._id);
                  console.log(`Rendering stats for user ${user.name}:`, stats);
                  return (
                    <TableRow key={user._id}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Chip 
                          label={user.role}
                          className={`${classes.roleChip} ${
                            user.role === 'admin' 
                              ? classes.adminChip 
                              : user.role === 'manager'
                                ? classes.managerChip
                                : classes.employeeChip
                          }`}
                        />
                      </TableCell>
                      <TableCell>
                        {user.lastLogin ? (
                          <Tooltip 
                            title={new Date(user.lastLogin).toLocaleString('en-US', {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true
                            })}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>
                                {new Date(user.lastLogin).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric'
                                })}
                              </span>
                              <span style={{ fontSize: '0.8em', color: 'grey' }}>
                                {new Date(user.lastLogin).toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true
                                })}
                              </span>
                            </div>
                          </Tooltip>
                        ) : (
                          <span style={{ color: 'grey' }}>Never</span>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className={classes.statsContainer}>
                          <Tooltip title="Total Rants">
                            <Badge 
                              badgeContent={stats.total} 
                              color="primary"
                              className={classes.badge}
                              overlap="rectangular"
                            >
                              <CommentIcon />
                            </Badge>
                          </Tooltip>
                          <Tooltip title="Resolved Rants">
                            <Chip
                              icon={<CheckCircleIcon />}
                              label={stats.resolved}
                              size="small"
                              className={classes.resolvedChip}
                            />
                          </Tooltip>
                          <Tooltip title="Pending Rants">
                            <Chip
                              icon={<ScheduleIcon />}
                              label={stats.pending}
                              size="small"
                              className={classes.pendingChip}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Box display="flex" alignItems="center">
                            <Chip 
                              label={user.status || 'pending'} 
                              color={
                                user.status === 'approved' ? 'primary' : 
                                user.status === 'rejected' ? 'secondary' : 
                                'default'
                              }
                              className={classes.statusChip}
                            />
                            
                            {currentUser?.role === 'admin' && (
                              <>
                                {user.status === 'pending' && (
                                  <>
                                    <Tooltip title="Approve Account">
                                      <IconButton
                                        size="small"
                                        onClick={() => handleApproveUser(user._id)}
                                        className={classes.approveButton}
                                      >
                                        <CheckCircleIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Reject Account">
                                      <IconButton
                                        size="small"
                                        onClick={() => handleRejectUser(user._id)}
                                        className={classes.rejectButton}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}

                                {user.status === 'approved' && (
                                  <Tooltip title="Deactivate Account">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleToggleStatus(user)}
                                      className={classes.rejectButton}
                                      disabled={user.role === 'manager' && user.isActive && activeManagerCount === 1}
                                    >
                                      <BlockIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}

                                {(user.status === 'rejected' || !user.isActive) && (
                                  <Tooltip title="Reactivate Account">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleApproveUser(user._id)}
                                      className={classes.approveButton}
                                    >
                                      <CheckCircleIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </Box>

                          {(currentUser?.role === 'admin' || 
                            (currentUser?.role === 'manager' && 
                             user.role !== 'manager' && 
                             user.role !== 'admin')) && (
                            <>
                              <Tooltip title="Edit User">
                                <IconButton 
                                  size="small"
                                  onClick={() => handleOpenDialog(user)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              
                              <Tooltip title="Delete User">
                                <IconButton 
                                  size="small"
                                  onClick={() => handleDeleteUser(user._id)}
                                  disabled={user.role === 'admin'}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={getFilteredAndSortedUsers().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedUser ? 'Edit User' : 'Add New User'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={classes.formField}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className={classes.formField}
            />
            <FormControl fullWidth className={classes.formField}>
              <InputLabel>Role</InputLabel>
              <Select
                name="role"
                value={formData.role}
                onChange={handleChange}
              >
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
                {currentUser?.role === 'admin' && (
                  <MenuItem value="admin">Admin</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={selectedUser ? "New Password (optional)" : "Password"}
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              className={classes.formField}
              required={!selectedUser}
            />
            {error && (
              <Typography color="error" className={classes.formField}>
                {error}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {selectedUser ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UserManagement; 
