import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../utils/api';
import { TextField, Button, Typography, Container, makeStyles, Paper } from '@material-ui/core';
import Notification from './Notification';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    maxWidth: 400,
    margin: '0 auto',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  }
}));

function Login() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const { login } = useContext(AuthContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Attempting login with:', formData);
      const res = await api.post('/api/auth/login', formData);
      console.log('Login response:', res.data);
      if (res.data.token) {
        await login(res.data.token);
        console.log('Login successful, redirecting to dashboard');
        history.push('/dashboard');
        enqueueSnackbar('Successfully logged in!', { 
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2000
        });
      }
    } catch (err) {
      console.error('Login error:', err.response?.data || err.message);
      
      // Handle different error cases
      const errorMsg = err.response?.data?.msg;
      if (errorMsg === 'Your account is pending approval') {
        setNotification({
          open: true,
          message: 'Your account is pending administrator approval. Please check your email for updates.',
          severity: 'warning'
        });
      } else if (errorMsg === 'Your account has been disabled') {
        setNotification({
          open: true,
          message: 'Your account has been disabled. Please contact an administrator.',
          severity: 'error'
        });
      } else if (errorMsg === 'Your account has been rejected') {
        setNotification({
          open: true,
          message: 'Your account registration has been rejected. Please contact an administrator.',
          severity: 'error'
        });
      } else {
        setNotification({
          open: true,
          message: 'Invalid email or password',
          severity: 'error'
        });
      }

      enqueueSnackbar(err.response?.data?.msg || 'Login failed', { 
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h4" gutterBottom>Login</Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            name="email"
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
        </form>
      </Paper>
      <Notification
        open={notification.open}
        handleClose={() => setNotification({ ...notification, open: false })}
        severity={notification.severity}
        message={notification.message}
      />
    </Container>
  );
}

export default Login;
