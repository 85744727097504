import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Box,
  Chip,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { 
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons';
import { AuthContext } from '../context/AuthContext';
import api from '../utils/api';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  chartContainer: {
    height: 400,
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  legend: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  colorBox: {
    width: 16,
    height: 16,
    borderRadius: 4,
  },
  tableSection: {
    marginTop: theme.spacing(4),
  },
  searchBox: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  statusChip: {
    margin: theme.spacing(0.5),
  },
  resolvedChip: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  pendingChip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  }
}));

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

function EmployeeDashboard() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [rantTrends, setRantTrends] = useState([]);
  const [rantCategories, setRantCategories] = useState([]);
  const [rants, setRants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const history = useHistory();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch chart data
        const trendsResponse = await api.get('/api/rants/trends');
        setRantTrends(trendsResponse.data);

        const categoriesResponse = await api.get('/api/rants/categories');
        setRantCategories(categoriesResponse.data);

        // Fetch rants data
        const rantsResponse = await api.get('/api/rants/my-rants');
        setRants(rantsResponse.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const getFilteredAndSortedRants = () => {
    return rants
      .filter(rant => 
        rant.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rant.department?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rant.categoryType?.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const multiplier = sortOrder === 'asc' ? 1 : -1;
        
        switch (sortBy) {
          case 'date':
            return multiplier * (new Date(a.date) - new Date(b.date));
          case 'department':
            return multiplier * a.department?.name.localeCompare(b.department?.name);
          case 'category':
            return multiplier * a.categoryType?.name.localeCompare(b.categoryType?.name);
          default:
            return 0;
        }
      });
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Welcome, {user?.name}
      </Typography>

      <Grid container spacing={3}>
        {/* Charts section */}
        <Grid item xs={12} md={7}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Rant Trends Over Time
            </Typography>
            <div className={classes.chartContainer}>
              <ResponsiveContainer>
                <LineChart data={rantTrends}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <ChartTooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="submitted" 
                    stroke="#8884d8" 
                    name="Submitted"
                  />
                  <Line 
                    type="monotone" 
                    dataKey="resolved" 
                    stroke="#82ca9d" 
                    name="Resolved"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Rants by Category
            </Typography>
            <div className={classes.chartContainer}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={rantCategories}
                    dataKey="count"
                    nameKey="category"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    label
                  >
                    {rantCategories.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={COLORS[index % COLORS.length]} 
                      />
                    ))}
                  </Pie>
                  <ChartTooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className={classes.legend}>
              {rantCategories.map((entry, index) => (
                <div key={entry.category} className={classes.legendItem}>
                  <div 
                    className={classes.colorBox} 
                    style={{ backgroundColor: COLORS[index % COLORS.length] }} 
                  />
                  <Typography variant="body2">
                    {entry.category} ({entry.count})
                  </Typography>
                </div>
              ))}
            </div>
          </Paper>
        </Grid>

        {/* Recent Rants Table */}
        <Grid item xs={12} className={classes.tableSection}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Recent Rants
            </Typography>

            <Box className={classes.searchBox}>
              <TextField
                placeholder="Search rants..."
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ width: 300 }}
              />
              
              <Box display="flex" alignItems="center" gap={2}>
                <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
                  <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="date">Sort by Date</MenuItem>
                    <MenuItem value="department">Sort by Department</MenuItem>
                    <MenuItem value="category">Sort by Category</MenuItem>
                  </Select>
                </FormControl>
                
                <Button
                  variant="outlined"
                  onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                >
                  {sortOrder === 'asc' ? '↑' : '↓'}
                </Button>
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>
                      Date {sortBy === 'date' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </TableCell>
                    <TableCell onClick={() => handleSort('department')} style={{ cursor: 'pointer' }}>
                      Department {sortBy === 'department' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </TableCell>
                    <TableCell onClick={() => handleSort('category')} style={{ cursor: 'pointer' }}>
                      Category {sortBy === 'category' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredAndSortedRants()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rant) => (
                      <TableRow key={rant._id}>
                        <TableCell>
                          {format(new Date(rant.date), 'MMM d, yyyy')}
                        </TableCell>
                        <TableCell>{rant.department?.name}</TableCell>
                        <TableCell>{rant.categoryType?.name}</TableCell>
                        <TableCell>
                          <Tooltip title={rant.content}>
                            <span>
                              {rant.content.length > 100 
                                ? `${rant.content.substring(0, 100)}...` 
                                : rant.content}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Chip
                            icon={rant.responses?.length > 0 ? <CheckCircleIcon /> : <ScheduleIcon />}
                            label={rant.responses?.length > 0 ? 'Resolved' : 'Pending'}
                            className={`${classes.statusChip} ${
                              rant.responses?.length > 0 ? classes.resolvedChip : classes.pendingChip
                            }`}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View Details">
                            <IconButton
                              size="small"
                              onClick={() => history.push(`/rant/${rant._id}`)}
                              color="primary"
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getFilteredAndSortedRants().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EmployeeDashboard;
