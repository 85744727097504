import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, TextField, TablePagination, TableSortLabel, makeStyles, Container, ButtonGroup, Button, Grid 
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import RantStatus from './RantStatus';
import { GridOn as ExcelIcon, ViewColumn as CsvIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  filterSection: {
    backgroundColor: theme.palette.background.alternate,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  rantCard: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.alternate,
    },
  },
  rantTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  rantMeta: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  statusChip: {
    margin: theme.spacing(0, 1),
  },
  statusPending: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusResolved: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  }
}));

function RantList() {
  const classes = useStyles();
  const [rants, setRants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [category, setCategory] = useState('');
  const [department, setDepartment] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // Last 7 days
    endDate: new Date()
  });

  useEffect(() => {
    const fetchRants = async () => {
      try {
        const res = await api.get('/api/rants');
        setRants(res.data.rants);
      } catch (err) {
        console.error('Error fetching rants:', err);
      }
    };
    fetchRants();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: 'author.name', label: 'Author' },
    { id: 'target.name', label: 'Target' },
    { id: 'department.name', label: 'Department' },
    { id: 'categoryType.name', label: 'Category' },
    { id: 'content', label: 'Content' },
    { id: 'date', label: 'Date' },
    { id: 'status', label: 'Status' },
  ];

  const filteredRants = rants.filter(rant => {
    const searchStr = searchTerm.toLowerCase();
    return (
      rant.author?.name?.toLowerCase().includes(searchStr) ||
      rant.target?.name?.toLowerCase().includes(searchStr) ||
      rant.department?.name?.toLowerCase().includes(searchStr) ||
      rant.categoryType?.name?.toLowerCase().includes(searchStr) ||
      rant.content?.toLowerCase().includes(searchStr) ||
      rant.status?.toLowerCase().includes(searchStr)
    );
  });

  const sortedRants = [...filteredRants].sort((a, b) => {
    if (orderBy === 'date') {
      return order === 'asc' 
        ? new Date(a.date) - new Date(b.date) 
        : new Date(b.date) - new Date(a.date);
    }
    
    // Handle nested object properties (author.name, target.name, etc.)
    const aValue = orderBy.includes('.') 
      ? orderBy.split('.').reduce((obj, key) => obj?.[key], a) 
      : a[orderBy];
    const bValue = orderBy.includes('.') 
      ? orderBy.split('.').reduce((obj, key) => obj?.[key], b) 
      : b[orderBy];

    if (!aValue) return order === 'asc' ? -1 : 1;
    if (!bValue) return order === 'asc' ? 1 : -1;

    return order === 'asc'
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });

  const paginatedRants = sortedRants.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleExportExcel = async () => {
    try {
      const response = await api.get('/api/reports/export-rants/excel', {
        params: {
          searchTerm,
          rantStatus: status,
          category,
          department,
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString()
        },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `rants-list-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting Excel:', error);
    }
  };

  const handleExportCSV = async () => {
    try {
      const response = await api.get('/api/reports/export-rants/csv', {
        params: {
          searchTerm,
          rantStatus: status,
          category,
          department,
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString()
        },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `rants-list-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>All Rants</Typography>
      <TextField
        className={classes.searchField}
        label="Search Rants"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Grid item xs={12}>
        <ButtonGroup variant="outlined" color="primary">
          <Button startIcon={<ExcelIcon />} onClick={handleExportExcel}>
            Export Excel
          </Button>
          <Button startIcon={<CsvIcon />} onClick={handleExportCSV}>
            Export CSV
          </Button>
        </ButtonGroup>
      </Grid>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRants.map((rant) => (
                <TableRow 
                  key={rant._id} 
                  className={classes.tableRow}
                  component={Link} 
                  to={`/rant/${rant._id}`}
                >
                  <TableCell>{rant.author?.name || 'Anonymous'}</TableCell>
                  <TableCell>{rant.target?.name}</TableCell>
                  <TableCell>{rant.department?.name}</TableCell>
                  <TableCell>{rant.categoryType?.name}</TableCell>
                  <TableCell>{rant.content?.substring(0, 50)}...</TableCell>
                  <TableCell>{new Date(rant.date).toLocaleString()}</TableCell>
                  <TableCell>
                    <RantStatus status={rant.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedRants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default RantList;

