import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import {
  Description as ArticleIcon,
  Visibility as ViewsIcon,
  Comment as CommentIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
  },
  statValue: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  statLabel: {
    color: theme.palette.text.secondary,
  },
  statIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  }
}));

function KnowledgeBaseCategoryStats({ stats }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Category Statistics
        </Typography>
        <Grid container spacing={2}>
          {stats.map((stat) => (
            <Grid item xs={12} sm={6} md={4} key={stat._id}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {stat.name}
                  </Typography>
                  
                  <Box display="flex" alignItems="center" mt={2}>
                    <Tooltip title="Total Articles">
                      <Box display="flex" alignItems="center" mr={2}>
                        <ArticleIcon className={classes.statIcon} />
                        <Typography variant="body1">
                          {stat.totalArticles}
                        </Typography>
                      </Box>
                    </Tooltip>

                    <Tooltip title="Total Views">
                      <Box display="flex" alignItems="center" mr={2}>
                        <ViewsIcon className={classes.statIcon} />
                        <Typography variant="body1">
                          {stat.totalViews}
                        </Typography>
                      </Box>
                    </Tooltip>

                    <Tooltip title="Total Comments">
                      <Box display="flex" alignItems="center">
                        <CommentIcon className={classes.statIcon} />
                        <Typography variant="body1">
                          {stat.totalComments}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>

                  <Box mt={1}>
                    <Typography variant="body2" color="textSecondary">
                      Average Rating:
                    </Typography>
                    <Rating 
                      value={stat.averageRating} 
                      precision={0.1} 
                      readOnly 
                      size="small"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

export default KnowledgeBaseCategoryStats; 