import React, { useState, useEffect, useCallback } from 'react';
import ReactConfetti from 'react-confetti';
import {
  Container,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  IconButton,
  Chip,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import api from '../utils/api';

// Add custom window size hook
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  statusHigh: {
    color: theme.palette.error.main,
  },
  statusMedium: {
    color: theme.palette.warning.main,
  },
  statusLow: {
    color: theme.palette.success.main,
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    fontWeight: 'bold',
  },
  sortLabel: {
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }
}));

function ActionPlans() {
  const classes = useStyles();
  const [plans, setPlans] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const [orderBy, setOrderBy] = useState('deadline');
  const [order, setOrder] = useState('asc');
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    department: '',
    deadline: '',
    priority: 'medium',
    assignedTo: ''
  });

  const fetchPlans = useCallback(async () => {
    try {
      const response = await api.get('/api/action-plans');
      setPlans(response.data);
    } catch (error) {
      console.error('Error fetching action plans:', error);
    }
  }, []);

  const fetchDepartments = useCallback(async () => {
    try {
      const response = await api.get('/api/manage/targetDepartments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  }, []);

  useEffect(() => {
    fetchPlans();
    fetchDepartments();
  }, [fetchPlans, fetchDepartments]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedPlan) {
        await api.put(`/api/action-plans/${selectedPlan._id}`, formData);
      } else {
        await api.post('/api/action-plans', formData);
      }
      fetchPlans();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving action plan:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPlan(null);
    setFormData({
      title: '',
      description: '',
      department: '',
      deadline: '',
      priority: 'medium',
      assignedTo: ''
    });
  };

  const handleToggleComplete = async (planId, completed) => {
    try {
      await api.put(`/api/action-plans/${planId}`, { 
        completed,
        completedDate: completed ? new Date() : null
      });
      
      if (completed) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
      }
      
      fetchPlans();
    } catch (error) {
      console.error('Error updating action plan:', error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortPlans = (plans) => {
    return [...plans].sort((a, b) => {
      let compareA = a[orderBy];
      let compareB = b[orderBy];

      // Handle special cases
      if (orderBy === 'department') {
        compareA = a.department?.name || '';
        compareB = b.department?.name || '';
      }
      
      // Handle dates
      if (orderBy === 'deadline' || orderBy === 'completedDate') {
        compareA = new Date(compareA || 0).getTime();
        compareB = new Date(compareB || 0).getTime();
      }

      // Handle completed status
      if (orderBy === 'completed') {
        compareA = Boolean(compareA);
        compareB = Boolean(compareB);
      }

      if (compareB < compareA) {
        return order === 'asc' ? 1 : -1;
      }
      if (compareB > compareA) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    });
  };

  const handleDelete = async (planId) => {
    if (window.confirm('Are you sure you want to delete this action plan?')) {
      try {
        await api.delete(`/api/action-plans/${planId}`);
        fetchPlans();
      } catch (error) {
        console.error('Error deleting action plan:', error);
      }
    }
  };

  return (
    <Container className={classes.root}>
      {showConfetti && (
        <ReactConfetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}

      <div className={classes.header}>
        <Typography variant="h4">Action Plans</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Create Action Plan
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={orderBy === 'title' ? order : 'asc'}
                  onClick={() => handleRequestSort('title')}
                  className={classes.sortLabel}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'department'}
                  direction={orderBy === 'department' ? order : 'asc'}
                  onClick={() => handleRequestSort('department')}
                  className={classes.sortLabel}
                >
                  Department
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'priority'}
                  direction={orderBy === 'priority' ? order : 'asc'}
                  onClick={() => handleRequestSort('priority')}
                  className={classes.sortLabel}
                >
                  Priority
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'deadline'}
                  direction={orderBy === 'deadline' ? order : 'asc'}
                  onClick={() => handleRequestSort('deadline')}
                  className={classes.sortLabel}
                >
                  Deadline
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'completed'}
                  direction={orderBy === 'completed' ? order : 'asc'}
                  onClick={() => handleRequestSort('completed')}
                  className={classes.sortLabel}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortPlans(plans).map((plan) => (
              <TableRow 
                key={plan._id}
                style={{
                  backgroundColor: plan.completed ? 'rgba(76, 175, 80, 0.1)' : 'inherit'
                }}
              >
                <TableCell>{plan.title}</TableCell>
                <TableCell>{plan.department?.name}</TableCell>
                <TableCell>
                  <Chip
                    label={plan.priority}
                    className={classes[`status${plan.priority.charAt(0).toUpperCase() + plan.priority.slice(1)}`]}
                  />
                </TableCell>
                <TableCell>{new Date(plan.deadline).toLocaleDateString()}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(plan.completed)}
                        onChange={(e) => handleToggleComplete(plan._id, e.target.checked)}
                        color="primary"
                      />
                    }
                    label={plan.completed ? 'Completed' : 'Pending'}
                  />
                  {plan.completedDate && (
                    <Typography variant="caption" color="textSecondary" display="block">
                      Completed on: {new Date(plan.completedDate).toLocaleDateString()}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setSelectedPlan(plan);
                      setFormData({
                        ...plan,
                        department: plan.department._id
                      });
                      setOpenDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(plan._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedPlan ? 'Edit Action Plan' : 'Create Action Plan'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className={classes.formField}
              required
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className={classes.formField}
              required
            />
            <FormControl fullWidth className={classes.formField}>
              <InputLabel>Department</InputLabel>
              <Select
                value={formData.department}
                onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                required
              >
                {departments.map((dept) => (
                  <MenuItem key={dept._id} value={dept._id}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.formField}>
              <InputLabel>Priority</InputLabel>
              <Select
                value={formData.priority}
                onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
                required
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="date"
              label="Deadline"
              value={formData.deadline}
              onChange={(e) => setFormData({ ...formData, deadline: e.target.value })}
              className={classes.formField}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {selectedPlan ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ActionPlans;