import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { useTheme as useCustomTheme } from '../context/ThemeContext';
import { getLogoBySize } from '../assets/logo';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    textDecoration: 'none',
  },
  logoIcon: {
    width: 32,
    height: 32,
    [theme.breakpoints.down('sm')]: {
      width: 28,
      height: 28,
    },
    '& .bolt': {
      animation: '$flash 2s infinite',
    },
    '& .bubble': {
      animation: '$pulse 2s infinite',
    },
  },
  logoText: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#ffffff',
    letterSpacing: '0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  '@keyframes flash': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.6,
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.05)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  }
}));

function Logo({ variant = 'default', style = 'modern', size = 32 }) {
  const classes = useStyles();
  const { isDarkMode } = useCustomTheme();

  const logoVariants = {
    modern: (
      <svg
        className={classes.logoIcon}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="bubble"
          d="M28 14C28 7.373 22.627 2 16 2S4 7.373 4 14c0 3.227 1.274 6.162 3.346 8.318L6 28l6.883-2.295A11.955 11.955 0 0016 26c6.627 0 12-5.373 12-12z"
          fill={isDarkMode ? '#4FC3F7' : '#00B0FF'}
        />
        <path
          className="bolt"
          d="M16 8l-6 8h6l-2 6 6-8h-6l2-6z"
          fill="#FFFFFF"
        />
      </svg>
    ),
    minimal: (
      <svg
        className={classes.logoIcon}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="bubble"
          d="M16 2C8.268 2 2 8.268 2 16s6.268 14 14 14c2.25 0 4.354-.563 6.25-1.5l5.281 1.5-1.531-5.281C27.437 22.354 28 20.25 28 18c0-7.732-6.268-14-14-14z"
          fill={isDarkMode ? '#4FC3F7' : '#00B0FF'}
        />
        <path
          className="bolt"
          d="M16 9l-4 6h4l-1.5 4 4-6h-4l1.5-4z"
          fill="#FFFFFF"
        />
      </svg>
    ),
    circular: (
      <svg
        className={classes.logoIcon}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="bubble"
          cx="16"
          cy="16"
          r="14"
          fill={isDarkMode ? '#4FC3F7' : '#00B0FF'}
        />
        <path
          className="bolt"
          d="M16 8l-6 8h6l-2 6 6-8h-6l2-6z"
          fill="#FFFFFF"
        />
      </svg>
    )
  };

  // For static image usage
  const logoSrc = getLogoBySize(style, size);

  return (
    <div className={classes.logoContainer}>
      {logoVariants[style]}
      {variant !== 'icon-only' && (
        <span className={classes.logoText}>
          Rant Analyzer
        </span>
      )}
    </div>
  );
}

export default Logo; 