import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import io from 'socket.io-client';
import api from '../utils/api';

export const NotificationContext = createContext();

export const TOAST_POSITIONS = {
  TOP_RIGHT: {
    top: 20,
    right: 20,
    bottom: 'auto'
  },
  BOTTOM_RIGHT: {
    bottom: 20,
    right: 20,
    top: 'auto'
  },
  TOP_LEFT: {
    top: 20,
    left: 20,
    right: 'auto'
  },
  BOTTOM_LEFT: {
    bottom: 20,
    left: 20,
    right: 'auto'
  },
  TOP_CENTER: {
    top: 20,
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)'
  }
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [toasts, setToasts] = useState([]);
  const { user } = useContext(AuthContext);
  const [toastPosition, setToastPosition] = useState(() => {
    const savedPosition = localStorage.getItem('toastPosition');
    return savedPosition || 'BOTTOM_RIGHT';
  });

  useEffect(() => {
    if (!user?.id) return;

    const socket = io(process.env.REACT_APP_API_URL || 'https://api.rantanalyzer.com', {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 10000,
      transports: ['websocket', 'polling']
    });

    socket.on('connect', () => {
      console.log('Socket connected, joining room:', `user-${user.id}`);
      socket.emit('join', user.id);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'transport close' && socket.connected === false) {
        socket.connect(); // Attempt to reconnect
      }
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    socket.on('notification', (newNotification) => {
      console.log('New notification received:', newNotification);
      setNotifications(prev => [newNotification, ...prev]);
      if (!newNotification.read) {
        setUnreadCount(prev => prev + 1);
        addToast(newNotification);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user?.id]);

  const addToast = (notification) => {
    const toastId = Date.now();
    const toast = {
      id: toastId,
      ...notification
    };
    setToasts(prev => [...prev, toast]);

    const TOAST_DURATION = {
      SHORT: 3000,    // 3 seconds
      MEDIUM: 5000,   // Current 5 seconds
      LONG: 7000,     // 7 seconds
    };

    setTimeout(() => {
      setToasts(prev => prev.filter(t => t.id !== toastId));
    }, TOAST_DURATION.MEDIUM);
  };

  const removeToast = (toastId) => {
    setToasts(prev => prev.filter(toast => toast.id !== toastId));
  };

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/api/auth/notifications');
      setNotifications(response.data);
      setUnreadCount(response.data.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await api.put(`/api/auth/notifications/${notificationId}/read`);
      setNotifications(prev => 
        prev.map(n => n._id === notificationId ? { ...n, read: true } : n)
      );
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      await api.put('/api/auth/notifications/mark-all-read');
      setNotifications(prev => prev.map(n => ({ ...n, read: true })));
      setUnreadCount(0);
    } catch (error) {
      console.error('Error marking all as read:', error);
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      await api.delete(`/api/auth/notifications/${notificationId}`);
      setNotifications(prev => prev.filter(n => n._id !== notificationId));
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const changeToastPosition = (position) => {
    setToastPosition(position);
    localStorage.setItem('toastPosition', position);
  };

  return (
    <NotificationContext.Provider value={{
      notifications,
      unreadCount,
      markAsRead,
      markAllAsRead,
      deleteNotification,
      fetchNotifications,
      toastPosition,
      changeToastPosition
    }}>
      {children}
      <div style={{
        position: 'fixed',
        ...TOAST_POSITIONS[toastPosition],
        zIndex: 2000,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        maxWidth: '400px'
      }}>
        {toasts.map(toast => (
          <div
            key={toast.id}
            style={{
              backgroundColor: toast.type === 'error' ? 'rgba(211, 47, 47, 0.9)' : 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '12px 24px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              border: '1px solid rgba(255,255,255,0.1)',
              animation: 'slideIn 0.5s ease-out'
            }}
            onClick={() => {
              if (toast.relatedId) {
                window.location.href = `/rant/${toast.relatedId}`;
              }
              removeToast(toast.id);
            }}
          >
            <div>
              <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{toast.title}</div>
              <div style={{ fontSize: '0.9em', opacity: 0.9 }}>{toast.message}</div>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                removeToast(toast.id);
              }}
              style={{
                background: 'none',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                padding: '4px',
                marginLeft: '12px',
                opacity: 0.7,
                fontSize: '16px',
                lineHeight: 1
              }}
            >
              ×
            </button>
          </div>
        ))}
      </div>
      <style>
        {`
          @keyframes slideIn {
            from {
              transform: translateX(100%);
              opacity: 0;
            }
            to {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes bounce {
            0% { transform: scale(0.3); opacity: 0; }
            50% { transform: scale(1.05); opacity: 0.8; }
            70% { transform: scale(0.9); opacity: 0.9; }
            100% { transform: scale(1); opacity: 1; }
          }
        `}
      </style>
    </NotificationContext.Provider>
  );
};
