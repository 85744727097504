import React, { useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { Container, Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Paper, makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  optionCard: {
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      backgroundColor: theme.palette.background.alternate,
    },
  },
  addButton: {
    marginBottom: theme.spacing(3),
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.background.alternate,
    },
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  }
}));

function ManageOptions() {
  const classes = useStyles();
  const [options, setOptions] = useState({
    targetEmployees: [],
    targetDepartments: [],
    categoryTypes: [],
  });
  const [newOption, setNewOption] = useState('');
  const [editingOption, setEditingOption] = useState(null);
  const [currentCategory, setCurrentCategory] = useState('targetEmployees');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  const fetchOptions = useCallback(async () => {
    try {
      const res = await api.get(`/api/manage/${currentCategory}`);
      setOptions(prev => ({ ...prev, [currentCategory]: res.data }));
    } catch (err) {
      console.error(`Error fetching ${currentCategory}:`, err);
      setNotification({ open: true, message: `Failed to fetch ${currentCategory}`, severity: 'error' });
    }
  }, [currentCategory]);

  useEffect(() => {
    fetchOptions();
  }, [currentCategory, fetchOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingOption) {
        await api.put(`/api/manage/${currentCategory}/${editingOption._id}`, { name: newOption });
        setNotification({ open: true, message: 'Option updated successfully', severity: 'success' });
      } else {
        await api.post(`/api/manage/${currentCategory}`, { name: newOption });
        setNotification({ open: true, message: 'Option added successfully', severity: 'success' });
      }
      setNewOption('');
      setEditingOption(null);
      fetchOptions();
    } catch (err) {
      console.error('Error submitting option:', err);
      setNotification({ open: true, message: err.response?.data?.msg || 'An error occurred', severity: 'error' });
    }
  };

  const handleEdit = (option) => {
    setEditingOption(option);
    setNewOption(option.name);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/api/manage/${currentCategory}/${id}`);
      setNotification({ open: true, message: 'Option deleted successfully', severity: 'success' });
      fetchOptions();
    } catch (err) {
      console.error('Error deleting option:', err);
      setNotification({ open: true, message: 'Failed to delete option', severity: 'error' });
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Manage Options</Typography>
      <Paper className={classes.root}>
        <div>
          <Button onClick={() => setCurrentCategory('targetEmployees')} color={currentCategory === 'targetEmployees' ? 'primary' : 'default'}>
            Target Employees
          </Button>
          <Button onClick={() => setCurrentCategory('targetDepartments')} color={currentCategory === 'targetDepartments' ? 'primary' : 'default'}>
            Target Departments
          </Button>
          <Button onClick={() => setCurrentCategory('categoryTypes')} color={currentCategory === 'categoryTypes' ? 'primary' : 'default'}>
            Category Types
          </Button>
        </div>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            label={`New ${currentCategory.slice(0, -1).replace('target', 'Target')}`}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            {editingOption ? 'Update' : 'Add'}
          </Button>
        </form>
        <List className={classes.list}>
          {options[currentCategory].map((option) => (
            <ListItem key={option._id}>
              <ListItemText primary={option.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(option)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(option._id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageOptions;
