import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { 
  Container, Typography, Grid, Card, CardContent, Paper, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { Bar, Pie, Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import RantStatus from './RantStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    height: '100%',
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  chartContainer: {
    marginBottom: theme.spacing(4),
  },
  statValue: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  trendPositive: {
    color: theme.palette.success.main,
  },
  trendNegative: {
    color: theme.palette.error.main,
  },
  chartPaper: {
    padding: theme.spacing(3),
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginBottom: theme.spacing(6),
    '& .MuiTablePagination-root': {
      overflow: 'visible',
    }
  },
}));

function ManagerDashboard() {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');
  const [timeRange, setTimeRange] = useState('week');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [searchTerm, setSearchTerm] = useState('');

  const timeRangeOptions = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'week', label: 'Last Week' },
    { value: 'month', label: 'Last Month' },
    { value: 'quarter', label: 'Last Quarter' },
    { value: '6months', label: 'Last 6 Months' },
    { value: 'year', label: 'Last Year' },
    { value: 'custom', label: 'Custom Range' }
  ];

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const res = await api.get(`/api/rants/manager-dashboard?timeRange=${timeRange}`);
        setDashboardData(res.data);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
      }
    };

    fetchDashboardData();
  }, [timeRange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTimeRangeChange = (event) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenDatePicker(true);
    } else {
      setTimeRange(value);
    }
  };

  const handleDateRangeSelect = () => {
    if (dateRange.startDate && dateRange.endDate) {
      setTimeRange(`custom:${dateRange.startDate.toISOString()}:${dateRange.endDate.toISOString()}`);
      setOpenDatePicker(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  if (!dashboardData) return <Typography>Loading...</Typography>;

  const filteredRants = dashboardData.recentRants.filter(rant => {
    const searchStr = searchTerm.toLowerCase();
    return (
      rant.author?.name?.toLowerCase().includes(searchStr) ||
      rant.target?.name?.toLowerCase().includes(searchStr) ||
      rant.department?.name?.toLowerCase().includes(searchStr) ||
      rant.categoryType?.name?.toLowerCase().includes(searchStr) ||
      rant.content?.toLowerCase().includes(searchStr) ||
      rant.status?.toLowerCase().includes(searchStr) ||
      new Date(rant.date).toLocaleString().toLowerCase().includes(searchStr)
    );
  });

  const sortedRants = filteredRants.sort((a, b) => {
    if (orderBy === 'date') {
      return order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
    }
    if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });

  const paginatedRants = sortedRants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const headCells = [
    { id: 'author.name', label: 'Author' },
    { id: 'target.name', label: 'Target' },
    { id: 'department.name', label: 'Department' },
    { id: 'categoryType.name', label: 'Category' },
    { id: 'content', label: 'Content' },
    { id: 'date', label: 'Date' },
    { id: 'status', label: 'Status' }
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Manager Dashboard</Typography>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              onChange={handleTimeRangeChange}
              label="Time Range"
            >
              {timeRangeOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  label="Start Date"
                  format="MM/dd/yyyy"
                  value={dateRange.startDate}
                  onChange={(date) => setDateRange({ ...dateRange, startDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  label="End Date"
                  format="MM/dd/yyyy"
                  value={dateRange.endDate}
                  onChange={(date) => setDateRange({ ...dateRange, endDate: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change end date',
                  }}
                  fullWidth
                  minDate={dateRange.startDate}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDatePicker(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDateRangeSelect} color="primary" variant="contained">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Total Rants</Typography>
                <Typography className={classes.statsValue}>
                  {dashboardData.totalRants}
                </Typography>
                <Typography className={classes.trendingUp} variant="body2">
                  +{dashboardData.rantsTrend}% from last {timeRange}
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Response Rate</Typography>
                <Typography className={classes.statsValue}>
                  {dashboardData.responseRate}%
                </Typography>
                <Typography variant="body2">
                  Average response time: {dashboardData.avgResponseTime} hours
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Active Cases</Typography>
                <Typography className={classes.statsValue}>
                  {dashboardData.activeCases}
                </Typography>
                <Typography className={classes.trendingDown} variant="body2">
                  {dashboardData.pendingRants} pending responses
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Resolution Rate</Typography>
                <Typography className={classes.statsValue}>
                  {dashboardData.resolutionRate}%
                </Typography>
                <Typography className={classes.trendingUp} variant="body2">
                  +{dashboardData.resolutionTrend}% from last {timeRange}
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Rant Trends Over Time</Typography>
                <Line
                  data={{
                    labels: dashboardData.trendData?.labels || [],
                    datasets: [
                      {
                        label: 'New Rants',
                        data: dashboardData.trendData?.newRants || [],
                        borderColor: '#36A2EB',
                        fill: false
                      },
                      {
                        label: 'Resolved Rants',
                        data: dashboardData.trendData?.resolvedRants || [],
                        borderColor: '#4BC0C0',
                        fill: false
                      }
                    ]
                  }}
                  options={{
                    scales: {
                      y: { beginAtZero: true }
                    }
                  }}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Rants by Category</Typography>
                <Pie
                  data={{
                    labels: dashboardData.categoryDistribution?.map(c => c.name) || [],
                    datasets: [{
                      data: dashboardData.categoryDistribution?.map(c => c.count) || [],
                      backgroundColor: [
                        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                        '#FF9F40'
                      ]
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Department Performance</Typography>
                <Bar
                  data={{
                    labels: dashboardData.departmentPerformance?.map(d => d.name) || [],
                    datasets: [
                      {
                        label: 'Total Rants',
                        data: dashboardData.departmentPerformance?.map(d => d.totalRants) || [],
                        backgroundColor: 'rgba(54, 162, 235, 0.5)'
                      },
                      {
                        label: 'Response Time (hours)',
                        data: dashboardData.departmentPerformance?.map(d => d.avgResponseTime) || [],
                        backgroundColor: 'rgba(255, 99, 132, 0.5)'
                      }
                    ]
                  }}
                  options={{
                    scales: {
                      y: { beginAtZero: true }
                    }
                  }}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Recent Rants</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Search Rants"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ marginBottom: '1rem' }}
                  placeholder="Search by author, target, department, category, content, status, or date..."
                />
                <TableContainer className={classes.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={() => handleRequestSort(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRants.map((rant) => (
                        <TableRow 
                          key={rant._id} 
                          className={classes.tableRow}
                          component={Link} 
                          to={`/rant/${rant._id}`}
                        >
                          <TableCell>{rant.author?.name || 'Anonymous'}</TableCell>
                          <TableCell>{rant.target?.name}</TableCell>
                          <TableCell>{rant.department?.name}</TableCell>
                          <TableCell>{rant.categoryType?.name}</TableCell>
                          <TableCell>{rant.content?.substring(0, 50)}...</TableCell>
                          <TableCell>{new Date(rant.date).toLocaleString()}</TableCell>
                          <TableCell>
                            <RantStatus status={rant.status || 'Pending'} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={sortedRants.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ManagerDashboard;
