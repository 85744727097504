import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(12),
    },
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  }
}));

function TermsOfService() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h1" className={classes.title}>
          Terms of Service
        </Typography>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            By accessing and using the Rant Analyzer platform, you agree to be bound by these Terms of Service.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            2. Use of Service
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            You agree to use the service only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            3. Content Guidelines
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Users must ensure that all submitted content is appropriate and does not violate any laws or rights of others. We reserve the right to remove inappropriate content.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            4. Intellectual Property
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            The service and its original content are protected by copyright, trademark, and other laws. Our trademarks and trade dress may not be used without prior written permission.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            5. Limitation of Liability
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the service.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="h6" className={classes.subtitle}>
            6. Changes to Terms
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            We reserve the right to modify these terms at any time. We will notify users of any changes by updating the date at the bottom of this page.
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="body1" className={classes.paragraph}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default TermsOfService; 